import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Form,
  LysaFormRef,
  RadioGroupSimpleCard,
  RequiredValidator,
} from "@lysaab/ui-2";
import { useAdvice } from "../../state/AdviceContext";
import { EligibilityRiskAnswer } from "../../data/types/Eligibility";

export enum RiskImportance {
  MAXIMIZE = 10,
  MINIMIZE = 0,
  BOTH = 5,
}

interface Props {
  next: () => void;
}

export const RiskImportanceQuestion: React.FC<Props> = ({ next }) => {
  const intl = useIntl();
  const advice = useAdvice();
  const formRef = useRef<LysaFormRef>();

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formRef.current?.isValid) {
        next();
      }
    },
    [next]
  );

  const alternatives = [
    {
      text: intl.formatMessage({ id: "risk.importance.alt.maximize" }),
      value: RiskImportance.MAXIMIZE,
    },
    {
      text: intl.formatMessage({ id: "risk.importance.alt.minimize" }),
      value: RiskImportance.MINIMIZE,
    },
    {
      text: intl.formatMessage({ id: "risk.importance.alt.both" }),
      value: RiskImportance.BOTH,
    },
  ];

  const value = advice.input[EligibilityRiskAnswer.IMPORTANCE];

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <RadioGroupSimpleCard
        legend={intl.formatMessage({ id: "risk.importance.header" })}
        alternatives={alternatives}
        value={value}
        onChange={(riskImportance) =>
          advice.setInput({
            [EligibilityRiskAnswer.IMPORTANCE]: riskImportance,
          })
        }
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "risk.importance.required" })
          ),
        ]}
        data-test-id="risk-importance-radiogroup"
      />

      <Button
        type="submit"
        block
        label={<FormattedMessage id="risk.importance.button.next" />}
        data-test-id="risk-importance-next-button"
      />
    </Form>
  );
};
