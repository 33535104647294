import React, { useContext } from "react";
import { WithdrawalInterval } from "../../pages/kyc/SharedKyc";
import { useIntl, defineMessages } from "react-intl";
import { RadioGroupSimpleCard, RequiredValidator } from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";

export const messages = defineMessages({
  header: {
    id: "kyc.withdrawalinterval.header",
  },
  required: {
    id: "kyc.withdrawalinterval.required",
  },
  [WithdrawalInterval.MONTHLY]: {
    id: "kyc.withdrawalinterval.alt.MONTHLY",
  },
  [WithdrawalInterval.MULTIPLE_YEARLY]: {
    id: "kyc.withdrawalinterval.alt.MULTIPLE_YEARLY",
  },
  [WithdrawalInterval.ONCE_YEARLY]: {
    id: "kyc.withdrawalinterval.alt.ONCE_YEARLY",
  },
  [WithdrawalInterval.SELDOM]: {
    id: "kyc.withdrawalinterval.alt.SELDOM",
  },
});

export const WithdrawalIntervalQuestion = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  const alternatives = [
    {
      text: intl.formatMessage(messages[WithdrawalInterval.MONTHLY]),
      value: WithdrawalInterval.MONTHLY,
    },
    {
      text: intl.formatMessage(messages[WithdrawalInterval.MULTIPLE_YEARLY]),
      value: WithdrawalInterval.MULTIPLE_YEARLY,
    },
    {
      text: intl.formatMessage(messages[WithdrawalInterval.ONCE_YEARLY]),
      value: WithdrawalInterval.ONCE_YEARLY,
    },
    {
      text: intl.formatMessage(messages[WithdrawalInterval.SELDOM]),
      value: WithdrawalInterval.SELDOM,
    },
  ];

  return (
    <RadioGroupSimpleCard
      alternatives={alternatives}
      legend={intl.formatMessage(messages.header)}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.required)),
      ]}
      onChange={(withdrawalInterval) =>
        signupContext.setState({ withdrawalInterval })
      }
      value={signupContext.state.withdrawalInterval}
      data-test-id="kyc-withdrawal-interval"
    />
  );
};
