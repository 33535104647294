import React, { useContext } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Card, CheckboxGroup, RequiredValidator } from "@lysaab/ui-2";
import { DenmarkContext, Bank } from "../../DenmarkContext";

const messages = defineMessages({
  bankHeader: { id: "denmark.kyc.bank.header" },
  bankRequired: { id: "denmark.kyc.bank.required" },
  [Bank.DK_DB]: { id: "denmark.kyc.bank.alt.DK_DB" },
  [Bank.DK_JYSKE]: { id: "denmark.kyc.bank.alt.DK_JYSKE" },
  [Bank.DK_NORDEA]: { id: "denmark.kyc.bank.alt.DK_NORDEA" },
  [Bank.DK_NYKREDIT]: { id: "denmark.kyc.bank.alt.DK_NYKREDIT" },
  [Bank.DK_SYDBANK]: { id: "denmark.kyc.bank.alt.DK_SYDBANK" },
  [Bank.DK_OTHER]: { id: "denmark.kyc.bank.alt.DK_OTHER" },
});

export const BankQuestion = () => {
  const intl = useIntl();
  const denmarkContext = useContext(DenmarkContext);

  return (
    <Card>
      <CheckboxGroup
        headerType="large"
        alternatives={[
          {
            text: intl.formatMessage(messages[Bank.DK_DB]),
            value: Bank.DK_DB,
          },
          {
            text: intl.formatMessage(messages[Bank.DK_JYSKE]),
            value: Bank.DK_JYSKE,
          },
          {
            text: intl.formatMessage(messages[Bank.DK_NORDEA]),
            value: Bank.DK_NORDEA,
          },
          {
            text: intl.formatMessage(messages[Bank.DK_NYKREDIT]),
            value: Bank.DK_NYKREDIT,
          },
          {
            text: intl.formatMessage(messages[Bank.DK_SYDBANK]),
            value: Bank.DK_SYDBANK,
          },
          {
            text: intl.formatMessage(messages[Bank.DK_OTHER]),
            value: Bank.DK_OTHER,
          },
        ]}
        header={intl.formatMessage(messages.bankHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.bankRequired)),
        ]}
        onChange={(banks) => denmarkContext.setState({ banks })}
        values={denmarkContext.state.banks || []}
      />
    </Card>
  );
};
