import {
  Button,
  Form,
  LysaFormRef,
  RadioGroupSimpleCard,
  RequiredValidator,
} from "@lysaab/ui-2";
import React, { useCallback, useRef } from "react";
import { NeedEarlierProbability } from "../../data/types/HorizonQuestions";
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from "react-intl";
import { useAdvice } from "../../state/AdviceContext";
interface Props {
  next: () => void;
}

export const HorizonProbability: React.FC<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const advice = useAdvice();

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formRef.current?.isValid) {
        next();
      }
    },
    [next, formRef]
  );

  const alternatives = Object.values(NeedEarlierProbability).map((key) => ({
    text: intl.formatMessage(needEarlierProbabilityAltText[key]),
    value: key,
  }));

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <RadioGroupSimpleCard
        legend={intl.formatMessage({ id: "horizon.probability.header" })}
        alternatives={alternatives}
        value={advice.input.needEarlier}
        onChange={(needEarlierProbability) =>
          advice.setInput({ needEarlier: needEarlierProbability })
        }
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "horizon.probability.required" })
          ),
        ]}
        data-test-id="horizon-probability"
      />

      <Button
        block
        type="submit"
        data-test-id="horizon-probability-next-button"
        label={<FormattedMessage id="horizon.probability.button.next" />}
      />
    </Form>
  );
};

const needEarlierProbabilityAltText = defineMessages<
  NeedEarlierProbability,
  MessageDescriptor,
  Record<NeedEarlierProbability, MessageDescriptor>
>({
  [NeedEarlierProbability.VERY_UNLIKELY]: {
    id: "horizon.probability.alt.veryunlikely",
  },
  [NeedEarlierProbability.UNLIKELY]: {
    id: "horizon.probability.alt.unlikely",
  },
  [NeedEarlierProbability.SOMEWHAT_UNLIKELY]: {
    id: "horizon.probability.alt.somewhatunlikely",
  },
  [NeedEarlierProbability.SOMEWHAT_LIKELY]: {
    id: "horizon.probability.alt.somewhatlikely",
  },
  [NeedEarlierProbability.LIKELY]: {
    id: "horizon.probability.alt.likely",
  },
  [NeedEarlierProbability.VERY_LIKELY]: {
    id: "horizon.probability.alt.verylikely",
  },
});
