import React, { useContext, useReducer } from "react";
import { Alternative, WorldCountry } from "@lysaab/ui-2";
import { SignupId } from "../data/signup";
import {
  DepositInterval,
  Employment,
  MoneyOrigin,
  Purpose,
  WithdrawalInterval,
} from "../pages/kyc/SharedKyc";
import { EligibilityPerson } from "../data/types/Eligibility";

export interface ReportingCountry {
  tin: string;
  /** This can be any country, doesn't have to be a country Lysa exists in */
  country?: WorldCountry;
}

/**
 * This is the state for all generic signup data.
 */
export type SignupState = Pick<EligibilityPerson, "financial"> & {
  /**
   * Setup
   */
  inviteId?: string;
  isFromApp?: boolean;

  /** Intro */
  email?: string;

  /** Confirmations */
  introDocuments?: Alternative<string>[];
  fatca?: boolean;
  localTaxResidence?: boolean;
  eligibilityRead?: Alternative<boolean>;
  privacyPolicyRead?: Alternative<boolean>;
  someoneElsesMoney?: boolean;
  spam?: boolean;

  /** Experience */
  hasInvested?: boolean;
  hasInvestedCheckbox?: boolean;
  upAndDown?: boolean;
  upAndDownCheckbox?: boolean;

  /** Allocation */
  allocationSelectedRisk?: number;
  allocationAdvicedRisk?: number;
  allocationForcedRisk?: number;
  allocationInvestment: number;
  allocationMonthlyInvestment?: string;

  /** Crs */
  crsReportingCountries?: ReportingCountry[];
  /** This can be any country, doesn't have to be a country Lysa exists in */
  crsResidenceCountry?: WorldCountry;
  crsFirstName?: string;
  crsLastName?: string;
  crsStreet?: string;
  crsPostCode?: string;
  crsCity?: string;
  crsBirthday?: string;

  /** KYC */
  depositInterval?: DepositInterval;
  employment?: Employment;
  moneyOrigin?: MoneyOrigin[];
  purpose?: Purpose[];
  withdrawalInterval?: WithdrawalInterval;

  /** Email verification */
  signedEmail?: string;

  /** Auth */
  password: string;
  totpSecret: string;
  totpSignature: string;
  totpSignedSecret?: string;

  /** Confirmation */
  signupId?: SignupId;

  /** Onfido */
  onfidoError: boolean;

  citizenships: WorldCountry[];
};

const defaultState: Omit<SignupState, "allocationInvestment"> = {
  financial: {
    monthlyEarnings: 0,
    monthlyPayments: 0,
    liquidAssets: 0,
    otherAssets: 0,
    debts: 0,
  },
  password: "",
  totpSecret: "",
  totpSignature: "",
  onfidoError: false,
  citizenships: [],
};

// All types for the context. This includes setter methods
export interface SignupContextProps {
  state: SignupState;
  setState: (newState: Partial<SignupState>) => void;
}

// Create the context. (This will just create a template)
export const SignupContext = React.createContext<SignupContextProps>(
  {} as SignupContextProps
);

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withSignup =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof SignupContextProps>>> =>
  (props) =>
    (
      <SignupContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </SignupContext.Consumer>
    );

function stateReducer(state: SignupState, newState: Partial<SignupState>) {
  return { ...state, ...newState } as SignupState;
}

interface Props {
  children: React.ReactNode;
  defaultInvestment: number;
}

export const SignupContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, defaultInvestment }) => {
  const [state, setState] = useReducer(stateReducer, {
    ...defaultState,
    allocationInvestment: defaultInvestment,
  });

  return (
    <SignupContext.Provider value={{ state, setState }}>
      {children}
    </SignupContext.Provider>
  );
};

export function useSignupContext() {
  const context = useContext(SignupContext);

  if (!context) {
    throw new Error(
      "useSignupContext must be used within a SignupContextProvider"
    );
  }

  return context;
}
