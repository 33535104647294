import React from "react";
import { useIntl } from "react-intl";
import { StorySectionIntro } from "../../components/storySectionIntro/StorySectionIntro";

interface Props {
  next: () => void;
}

export const HorizonIntro: React.FC<Props> = ({ next }) => {
  const intl = useIntl();

  return (
    <StorySectionIntro
      icon="Calendar"
      header={intl.formatMessage({
        id: "horizon-intro.header",
      })}
      description={intl.formatMessage({
        id: "horizon-intro.description",
      })}
      nextLabel={intl.formatMessage({
        id: "horizon-intro.next",
      })}
      nextTestId="horizon-intro-next-button"
      next={next}
    />
  );
};
