import { Alternative, ServerError, Spinner } from "@lysaab/ui-2";
import React, { useContext, useState } from "react";
import { SignupContext } from "../../state/SignupContext";
import { PepContext } from "@lysaab/lysa-pep";
import { LocalizationContext } from "../../state/LocalizationContext";
import { useIntl } from "react-intl";
import { ConfirmationTextSection } from "./ConfirmationTextSection";
import { ConfirmationSummarySection } from "./ConfirmationSummarySection";
import { ConfirmationErrorSnackbar } from "./ConfirmationErrorSnackbar";
import { ConfirmationHeader } from "./ConfirmationHeader";
import { ConfirmationNextButton } from "./ConfirmationNextButton";
import { AccountType, createSignupData, messages } from "./ConfirmationHelpers";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { saveSignup, SignupId } from "../../data/signup";
import { useDocumentsInformation } from "../../hooks/useDocumentsInformation";
import { useAdvice } from "../../state/AdviceContext";

interface Props {
  banks?: Alternative<string>[];
  deposits?: string;
  next: (signupId: SignupId) => void;
}

export const Confirmation = ({ banks, deposits, next }: Props) => {
  const signupContext = useContext(SignupContext);
  const pepContext = useContext(PepContext);
  const localizationContext = useContext(LocalizationContext);
  const advice = useAdvice();
  const intl = useIntl();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    information,
    error: documentsError,
    isLoading: isLoadingInformation,
  } = useDocumentsInformation();

  const setSignupContextState = signupContext.setState;

  const signup = () => {
    EventTracker.track({ event: TrackerEvent.CONFIRM });

    const signupRequest = createSignupData(
      localizationContext.state,
      intl.formatMessage(messages.accountName),
      signupContext.state,
      pepContext.state,
      advice.input,
      advice.result,
      banks,
      deposits
    );

    if (signupRequest === null) {
      console.error(
        "signupRequest === null",
        JSON.stringify(
          {
            localizationContext: {
              currency: localizationContext.state.currency,
              language: localizationContext.state.language,
              country: localizationContext.state.country,
              // situation is too large to log
            },
            signupContext: signupContext.state,
            pepContext: pepContext.state,
            banks,
            deposits,
          },
          null,
          2
        )
      );

      setError(true);

      return;
    }

    setLoading(true);
    saveSignup(signupRequest)
      .then((response) => {
        setLoading(false);
        setSignupContextState({ signupId: response.signupId });
        EventTracker.track({ event: TrackerEvent.SUBMITTED });
        next(response.signupId);
      })
      .catch((error: ServerError<any>) => {
        setLoading(false);
        const signupRequestCopy = { ...signupRequest };
        const signupStateCopy = { ...signupContext.state };
        console.error(JSON.stringify(error, Object.getOwnPropertyNames(error)));
        console.error(
          JSON.stringify(
            {
              localizationContext: {
                currency: localizationContext.state.currency,
                language: localizationContext.state.language,
                country: localizationContext.state.country,
                // situation is too large to log
              },
              signupContext: signupStateCopy,
              pepContext: pepContext.state,
              banks,
              deposits,
              signupRequest: signupRequestCopy,
            },
            null,
            2
          )
        );
        setError(true);
      });
  };

  return (
    <div className="confirmation-page">
      <ConfirmationHeader />

      {loading || isLoadingInformation ? (
        <Spinner />
      ) : (
        <>
          {(error || documentsError) && <ConfirmationErrorSnackbar />}
          <ConfirmationTextSection documentsInformation={information} />
          <ConfirmationSummarySection accountType={AccountType.VP} />

          <ConfirmationNextButton onClick={signup} />
        </>
      )}
    </div>
  );
};
