import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Form,
  LysaFormRef,
  RadioGroupSimpleCard,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import { List } from "./components/list/List";
import { EligibilityRiskAnswer } from "../../data/types/Eligibility";
import { useAdvice } from "../../state/AdviceContext";
import { Illustration } from "./components/illustration/Illustration";
import graphReaction from "./components/illustration/graph_reaction.svg";

export enum RiskReaction {
  SELL = 3,
  KEEP = 7,
  BUY = 10,
}

interface Props {
  next: () => void;
}

export const RiskReactionQuestion: React.FC<Props> = ({ next }) => {
  const intl = useIntl();
  const advice = useAdvice();
  const formRef = useRef<LysaFormRef>();

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formRef.current?.isValid) {
        next();
      }
    },
    [next]
  );

  const alternatives = [
    {
      text: intl.formatMessage({ id: "risk.reaction.alt.sell" }),
      value: RiskReaction.SELL,
    },
    {
      text: intl.formatMessage({ id: "risk.reaction.alt.keep" }),
      value: RiskReaction.KEEP,
    },
    {
      text: intl.formatMessage({ id: "risk.reaction.alt.buy" }),
      value: RiskReaction.BUY,
    },
  ];

  const value = advice.input[EligibilityRiskAnswer.REACTION];

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <Typography component="span" type="body" variant="secondary">
        <b>
          <FormattedMessage id="risk.reaction.header" />
        </b>
      </Typography>

      <Typography component="p" type="h3">
        <FormattedMessage id="risk.reaction.question.part-one" />
      </Typography>

      <List
        items={[
          {
            text: {
              text: intl.formatMessage(
                { id: "risk.reaction.question.part-two" },
                {
                  strong: (parts: React.ReactNode[]) => (
                    <strong>{parts}</strong>
                  ),
                }
              ),
            },
            discColor: "var(--lysa-text-blue)",
          },
        ]}
      />

      <Illustration
        label={intl.formatMessage({ id: "risk.reaction.illustration" })}
        src={graphReaction}
      />

      <RadioGroupSimpleCard
        legend={intl.formatMessage({
          id: "risk.reaction.question.part-three",
        })}
        alternatives={alternatives}
        value={value}
        onChange={(riskReaction) =>
          advice.setInput({
            [EligibilityRiskAnswer.REACTION]: riskReaction,
          })
        }
        validators={[
          new RequiredValidator(
            intl.formatMessage({
              id: "risk.reaction.required",
            })
          ),
        ]}
        data-test-id="risk-reaction-radiogroup"
      />

      <Button
        type="submit"
        block
        data-test-id="risk-reaction-next-button"
        label={<FormattedMessage id="risk.reaction.button.next" />}
      />
    </Form>
  );
};
