import React, { FunctionComponent, useContext, useReducer } from "react";
import { Alternative } from "@lysaab/ui-2";

export enum Bank {
  ES_SAN = "ES_SAN",
  ES_BBVA = "ES_BBVA",
  ES_CAIXA = "ES_CAIXA",
  ES_SABADELL = "ES_SABADELL",
  ES_BANKIA = "ES_BANKIA",
  ES_OTHER = "ES_OTHER",
}

export enum Deposits {
  ES_0_10K_EUR = "ES_0_10K_EUR",
  ES_10K_100K_EUR = "ES_10K_100K_EUR",
  ES_100K_500K_EUR = "ES_100K_500K_EUR",
  ES_500K_EUR = "ES_500K_EUR",
}

/**
 * This is the state for all generic Spain data.
 */
export interface SpainState {
  banks?: Alternative<Bank>[];
  deposits?: Deposits;
}

export interface SpainContextProps {
  state: SpainState;
  setState: (newState: Partial<SpainState>) => void;
}

const SpainContext = React.createContext<SpainContextProps | undefined>(
  undefined
);

const initialState: SpainState = {};

function stateReducer(state: SpainState, newState: Partial<SpainState>) {
  return { ...state, ...newState };
}

export const SpainContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, initialState);

  return (
    <SpainContext.Provider value={{ state, setState }}>
      {children}
    </SpainContext.Provider>
  );
};

export function useSpainContext() {
  const context = useContext(SpainContext);

  if (!context) {
    throw new Error(
      "useSpainContext must be used within a SpainContextProvider"
    );
  }

  return context;
}
