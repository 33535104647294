import { useIntl, defineMessages } from "react-intl";
import React, { useContext } from "react";
import {
  Alternative,
  RadioGroupSimpleCard,
  RequiredValidator,
} from "@lysaab/ui-2";
import { SignupContext } from "../../../state/SignupContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../../utils/eventTracker/EventTracker";

const messages = defineMessages({
  fatcaAlternativeYes: { id: "about_you.fatca.yes" },
  fatcaAlternativeNo: { id: "about_you.fatca.no" },
  fatcaHeader: { id: "about_you.fatca.header" },
  fatcaRequired: { id: "about_you.fatca.required" },
});

export const FatcaQuestion = () => {
  const signupContext = useContext(SignupContext);
  const intl = useIntl();

  const fatcaAlternatives: Alternative<boolean>[] = [
    {
      text: intl.formatMessage(messages.fatcaAlternativeYes),
      value: true,
    },
    {
      text: intl.formatMessage(messages.fatcaAlternativeNo),
      value: false,
    },
  ];

  return (
    <RadioGroupSimpleCard
      legend={intl.formatMessage(messages.fatcaHeader)}
      alternatives={fatcaAlternatives}
      value={signupContext.state.fatca}
      onChange={(value) => {
        EventTracker.track({
          event: TrackerEvent.FATCA_QUESTION,
          message: { answer: value },
        });
        signupContext.setState({ fatca: value });
      }}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.fatcaRequired)),
      ]}
      data-test-id="fatca"
    />
  );
};
