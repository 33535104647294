import React from "react";
import { Typography } from "@lysaab/ui-2";
import {
  TypographyText,
  typographyTextToProps,
} from "@lysaab/ui-2/components/typography/Typography";

import "./List.scss";

interface Item {
  text: TypographyText;
  discColor: string;
}

interface Props {
  items: Item[];
}

export const List: React.FC<Props> = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <ul className="List">
      {items.map((item, index) => (
        <li key={index} className="List__item">
          <div
            className="List__disc"
            style={{
              backgroundColor: item.discColor,
            }}
          ></div>
          <Typography
            {...typographyTextToProps(item.text)}
            type="body"
            component="span"
          />
        </li>
      ))}
    </ul>
  );
};
