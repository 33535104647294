import React from "react";
import { Snackbar, SNACKBAR_TYPES, Button } from "@lysaab/ui-2";
import "./HorizonWarning.scss";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import { ROUTE as HORIZON_LENGTH_ROUTE } from "../horizon/HorizonLength";

export const HorizonWarning = () => {
  const countryUrl = useCountryUrl();
  const history = useHistory();

  return (
    <div className="horizon-warning">
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <p>
          <FormattedMessage
            id="horizonwarning.text.warning"
            values={{
              b: (text: React.ReactNode[]) => <strong>{text}</strong>,
            }}
          />
        </p>
        <Button
          variant="secondary"
          block
          onClick={() => {
            history.push(countryUrl + HORIZON_LENGTH_ROUTE);
          }}
          label={<FormattedMessage id="horizonwarning.link.horizon" />}
        />
      </Snackbar>
    </div>
  );
};
