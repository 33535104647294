import React, {
  FunctionComponent,
  useRef,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Card,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  RequiredValidator,
  OtpInput,
  EmailValidator,
  EmailInput,
  OtpValidator,
  STORY_ANIMATION_DURATION,
  AnimateHeight,
  Spinner,
  Button,
} from "@lysaab/ui-2";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { SignupContext } from "../../state/SignupContext";
import {
  verifyEmail,
  verifyEmailCode,
  EmailVerificationCodeResult,
  saveInterested,
  removeInterested,
} from "../../data/signup";

import "./VerifyEmail.scss";
import { LocalizationContext } from "../../state/LocalizationContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { useInterval } from "../../hooks/useInterval";
import { MailStatus, mailStatus } from "../../data/mail";

export const ROUTE = "/email";

const messages = defineMessages({
  label_email: { id: "verifyemail.email.label" },
  email_required_msg: { id: "verifyemail.email.req" },
  email_valid_msg: { id: "verifyemail.email.valid" },
  email_suggestion: { id: "verifyemail.email.suggestion" },
  label_otp: { id: "verifyemail.otp.label" },
  otp_required_msg: { id: "verifyemail.otp.req" },
  otp_valid_msg: { id: "verifyemail.otp.valid" },
  error_not_valid: { id: "verifyemail.otp.response.not_valid" },
  error_expired: { id: "verifyemail.otp.response.expired" },
  error_send: { id: "verifyemail.error.send" },
});

interface Props {
  next: () => void;
}

export const VerifyEmail: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();
  const formRef2 = useRef<LysaFormRef>();
  const emailRef = useRef<HTMLInputElement>(null);
  const [totp, setTotp] = useState("");
  const [error, setError] = useState("");
  const [emailConfirmationId, setEmailConfirmationId] = useState("");
  const [email, setEmail] = useState(signupContext.state.email || "");
  const [isExpired, setIsExpired] = useState(true);
  const [mailStatusId, setMailStatusId] = useState("");
  const [sendError, setSendError] = useState("");

  useInterval(
    () => {
      mailStatus(mailStatusId).then(({ state }) => {
        if (state === MailStatus.ERROR) {
          setSendError(intl.formatMessage(messages.error_send));
        }
      });
    },
    mailStatusId ? 1000 : null
  );

  useEffect(() => {
    setTimeout(() => emailRef.current?.focus(), STORY_ANIMATION_DURATION);
  }, []);

  const country = localizationContext.state.country;

  if (!country) {
    return <Spinner />;
  }

  return (
    <div className="verify-email-page">
      <h1>
        <FormattedMessage id="verifyemail.header" />
      </h1>

      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          setMailStatusId("");

          if (isExpired && formRef.current?.isValid) {
            verifyEmail({
              email,
              language: localizationContext.state.language,
              country,
            })
              .then(({ confirmationId, statusIdResponse }) => {
                EventTracker.track({
                  event: TrackerEvent.VERIFY_EMAIL,
                  message: {
                    email,
                  },
                });
                setEmailConfirmationId(confirmationId);
                setMailStatusId(statusIdResponse);
                setIsExpired(false);
              })
              .catch((e) => {
                setSendError(intl.formatMessage(messages.error_send));
              });
          }
        }}
      >
        <Card>
          <h2>
            <FormattedMessage id="verifyemail.email.header" />
          </h2>
          <p>
            <FormattedMessage id="verifyemail.email.description" />
          </p>
          <EmailInput
            label={intl.formatMessage(messages.label_email)}
            value={email}
            ref={emailRef}
            onChange={(email) => {
              if (!isExpired) {
                setIsExpired(true);
              }
              setEmail(email);
            }}
            disabled={!!signupContext.state.signedEmail}
            suggestionMessage={intl.formatMessage(messages.email_suggestion, {
              // This replacement is made inside <EmailInput>, so pass it along
              suggestion: "{suggestion}",
            })}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.email_required_msg)
              ),
              new EmailValidator(intl.formatMessage(messages.email_valid_msg)),
            ]}
          />
          {sendError && <p className="send-error">{sendError}</p>}
          {signupContext.state.signedEmail ? (
            <>
              <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                <FormattedMessage id="verifyemail.disabled.text" />
              </Snackbar>
              <Button
                block
                type="button"
                onClick={next}
                label={<FormattedMessage id="verifyemail.disabled.next" />}
              />
            </>
          ) : (
            <Button
              block
              type="submit"
              data-test-id="sendCode-button"
              label={<FormattedMessage id="verifyemail.button.send_code" />}
            />
          )}
        </Card>
      </Form>

      <AnimateHeight isOpen={!!emailConfirmationId}>
        <Form
          lysaFormRef={formRef2}
          onSubmit={(event) => {
            event.preventDefault();

            if (formRef2.current?.isValid) {
              verifyEmailCode({
                code: totp,
                confirmationId: emailConfirmationId,
              }).then((response) => {
                EventTracker.track({
                  event: TrackerEvent.VERIFY_EMAIL_CODE,
                  message: {
                    status: response.result,
                  },
                });
                if (response.result === EmailVerificationCodeResult.OK) {
                  signupContext.setState({ signedEmail: response.signedEmail });

                  // If the user confirms another email than he/she entered at
                  // the beginning, we want to remember that email instead, and
                  // perhaps more importantly, not keep sending spammy "please
                  // continue your registration" to the first email
                  if (
                    signupContext.state.email &&
                    localizationContext.state.country &&
                    email !== signupContext.state.email &&
                    signupContext.state.spam
                  ) {
                    removeInterested(
                      signupContext.state.email,
                      localizationContext.state.country
                    );
                    saveInterested(
                      email,
                      localizationContext.state.language,
                      localizationContext.state.country
                    );
                  }

                  signupContext.setState({ email });

                  next();
                } else if (
                  response.result === EmailVerificationCodeResult.EXPIRED
                ) {
                  setError(intl.formatMessage(messages.error_expired));
                  setIsExpired(true);
                } else {
                  setError(intl.formatMessage(messages.error_not_valid));
                }
              });
            }
          }}
        >
          <Card>
            <h2>
              <FormattedMessage id="verifyemail.code.header" />
            </h2>
            <p>
              <FormattedMessage id="verifyemail.code.description" />
            </p>
            <OtpInput
              label={intl.formatMessage(messages.label_otp)}
              value={totp}
              onChange={(totp) => {
                setTotp(totp);
                setError("");
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.otp_required_msg)
                ),
                new OtpValidator(intl.formatMessage(messages.otp_valid_msg)),
              ]}
            />
          </Card>

          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              {error}
            </Snackbar>
          )}

          <Button
            block
            type="submit"
            data-test-id="verifyEmail-next-button"
            label={<FormattedMessage id="verifyemail.button.next" />}
          />
        </Form>
      </AnimateHeight>
    </div>
  );
};
