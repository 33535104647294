import { useIntl, defineMessages } from "react-intl";
import React, { useContext } from "react";
import {
  Alternative,
  RadioGroupSimpleCard,
  RequiredValidator,
} from "@lysaab/ui-2";
import { SignupContext } from "../../../state/SignupContext";
import { LocalizationContext } from "../../../state/LocalizationContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../../utils/eventTracker/EventTracker";

const messages = defineMessages({
  taxResidenceAlternativeYes: { id: "about_you.taxresidence.yes" },
  taxResidenceAlternativeNo: { id: "about_you.taxresidence.no" },
  taxResidenceHeader: { id: "about_you.taxresidence.header" },
  taxResidenceRequired: { id: "about_you.taxresidence.required" },
});

export const TaxResidenceQuestion = () => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  const localTaxResidenceAlternatives: Alternative<boolean>[] = [
    {
      text: intl.formatMessage(messages.taxResidenceAlternativeYes),
      value: true,
    },
    {
      text: intl.formatMessage(messages.taxResidenceAlternativeNo),
      value: false,
    },
  ];

  return (
    <RadioGroupSimpleCard
      legend={intl.formatMessage(messages.taxResidenceHeader, {
        country: localizationContext.state.country,
      })}
      alternatives={localTaxResidenceAlternatives}
      value={signupContext.state.localTaxResidence}
      onChange={(value) => {
        EventTracker.track({
          event: TrackerEvent.PRIMARY_TAX_RESIDENCE_QUESTION,
          message: { answer: value },
        });
        signupContext.setState({ localTaxResidence: value });
      }}
      validators={[
        new RequiredValidator(
          intl.formatMessage(messages.taxResidenceRequired)
        ),
      ]}
      data-test-id="tax-residence"
    />
  );
};
