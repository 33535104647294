import React, { FunctionComponent, useContext, useReducer } from "react";
import { Alternative } from "@lysaab/ui-2";

export enum Bank {
  DE_DB = "DE_DB",
  DE_DZ = "DE_DZ",
  DE_KFW = "DE_KFW",
  DE_COMMERZ = "DE_COMMERZ",
  DE_HYPO = "DE_HYPO",
  DE_OTHER = "DE_OTHER",
}

export enum Deposits {
  DE_0_10K_EUR = "DE_0_10K_EUR",
  DE_10K_100K_EUR = "DE_10K_100K_EUR",
  DE_100K_500K_EUR = "DE_100K_500K_EUR",
  DE_500K_EUR = "DE_500K_EUR",
}

/**
 * This is the state for all generic Germany data.
 */
export interface GermanyState {
  banks?: Alternative<Bank>[];
  deposits?: Deposits;
}

export interface GermanyContextProps {
  state: GermanyState;
  setState: (newState: Partial<GermanyState>) => void;
}

const GermanyContext = React.createContext<GermanyContextProps | undefined>(
  undefined
);

const initialState: GermanyState = {};

function stateReducer(state: GermanyState, newState: Partial<GermanyState>) {
  return { ...state, ...newState };
}

export const GermanyContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, initialState);

  return (
    <GermanyContext.Provider value={{ state, setState }}>
      {children}
    </GermanyContext.Provider>
  );
};

export function useGermanyContext() {
  const context = useContext(GermanyContext);

  if (!context) {
    throw new Error(
      "useGermanyContext must be used within a GermanyContextProvider"
    );
  }

  return context;
}
