import React, { useContext } from "react";
import { useIntl, defineMessages } from "react-intl";
import { RadioGroupSimpleCard, RequiredValidator } from "@lysaab/ui-2";
import { useGermanyContext, Deposits } from "../../GermanyContext";
import { LocalizationContext } from "../../../state/LocalizationContext";

export const messages = defineMessages({
  depositsHeader: { id: "germany.kyc.deposits.header" },
  depositsRequired: { id: "germany.kyc.deposits.required" },
  [Deposits.DE_0_10K_EUR]: { id: "germany.kyc.deposits.alt.DE_0_10K_EUR" },
  [Deposits.DE_10K_100K_EUR]: {
    id: "germany.kyc.deposits.alt.DE_10K_100K_EUR",
  },
  [Deposits.DE_100K_500K_EUR]: {
    id: "germany.kyc.deposits.alt.DE_100K_500K_EUR",
  },
  [Deposits.DE_500K_EUR]: { id: "germany.kyc.deposits.alt.DE_500K_EUR" },
});

export const DepositsQuestion = () => {
  const intl = useIntl();
  const germanyContext = useGermanyContext();
  const localizationContext = useContext(LocalizationContext);

  return (
    <RadioGroupSimpleCard
      alternatives={[
        {
          text: intl.formatMessage(messages[Deposits.DE_0_10K_EUR], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.DE_0_10K_EUR,
        },
        {
          text: intl.formatMessage(messages[Deposits.DE_10K_100K_EUR], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.DE_10K_100K_EUR,
        },
        {
          text: intl.formatMessage(messages[Deposits.DE_100K_500K_EUR], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.DE_100K_500K_EUR,
        },
        {
          text: intl.formatMessage(messages[Deposits.DE_500K_EUR], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.DE_500K_EUR,
        },
      ]}
      legend={intl.formatMessage(messages.depositsHeader)}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.depositsRequired)),
      ]}
      onChange={(deposits) => germanyContext.setState({ deposits })}
      value={germanyContext.state.deposits}
      data-test-id="kyc-deposits"
    />
  );
};
