import React, { FunctionComponent, useReducer } from "react";
import { Alternative } from "@lysaab/ui-2";

export enum Bank {
  DK_DB = "DK_DB",
  DK_JYSKE = "DK_JYSKE",
  DK_NORDEA = "DK_NORDEA",
  DK_NYKREDIT = "DK_NYKREDIT",
  DK_SYDBANK = "DK_SYDBANK",
  DK_OTHER = "DK_OTHER",
}

export enum Deposits {
  DK_0_100K_DKK = "DK_0_100K_DKK",
  DK_100K_1M_DKK = "DK_100K_1M_DKK",
  DK_1M_5M_DKK = "DK_1M_5M_DKK",
  DK_5M_DKK = "DK_5M_DKK",
}

/**
 * This is the state for all generic denmark data.
 */
export interface DenmarkState {
  banks?: Alternative<Bank>[];
  deposits?: Deposits;
}

// All types for the context. This includes setter methods
export interface DenmarkContextProps {
  state: DenmarkState;
  setState: (newState: Partial<DenmarkState>) => void;
}

// Create the context. (This will just create a template)
export const DenmarkContext = React.createContext<DenmarkContextProps>(
  {} as DenmarkContextProps
);

const initialState: DenmarkState = {};

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withDenmark =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof DenmarkContextProps>>> =>
  (props) =>
    (
      <DenmarkContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </DenmarkContext.Consumer>
    );

function stateReducer(state: DenmarkState, newState: Partial<DenmarkState>) {
  return { ...state, ...newState };
}

export const DenmarkContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, initialState);

  return (
    <DenmarkContext.Provider value={{ state, setState }}>
      {children}
    </DenmarkContext.Provider>
  );
};
