import { useIntl, defineMessages } from "react-intl";
import { Card, CheckboxGroup, RequiredValidator } from "@lysaab/ui-2";
import { useGermanyContext, Bank } from "../../GermanyContext";

const messages = defineMessages({
  bankHeader: { id: "germany.kyc.bank.header" },
  bankRequired: { id: "germany.kyc.bank.required" },
  [Bank.DE_DB]: { id: "germany.kyc.bank.alt.DE_DB" },
  [Bank.DE_DZ]: { id: "germany.kyc.bank.alt.DE_DZ" },
  [Bank.DE_KFW]: { id: "germany.kyc.bank.alt.DE_KFW" },
  [Bank.DE_COMMERZ]: { id: "germany.kyc.bank.alt.DE_COMMERZ" },
  [Bank.DE_HYPO]: { id: "germany.kyc.bank.alt.DE_HYPO" },
  [Bank.DE_OTHER]: { id: "germany.kyc.bank.alt.DE_OTHER" },
});

export const BankQuestion = () => {
  const intl = useIntl();
  const germanyContext = useGermanyContext();

  return (
    <Card>
      <CheckboxGroup
        headerType="large"
        alternatives={[
          {
            text: intl.formatMessage(messages[Bank.DE_DB]),
            value: Bank.DE_DB,
          },
          {
            text: intl.formatMessage(messages[Bank.DE_DZ]),
            value: Bank.DE_DZ,
          },
          {
            text: intl.formatMessage(messages[Bank.DE_KFW]),
            value: Bank.DE_KFW,
          },
          {
            text: intl.formatMessage(messages[Bank.DE_COMMERZ]),
            value: Bank.DE_COMMERZ,
          },
          {
            text: intl.formatMessage(messages[Bank.DE_HYPO]),
            value: Bank.DE_HYPO,
          },
          {
            text: intl.formatMessage(messages[Bank.DE_OTHER]),
            value: Bank.DE_OTHER,
          },
        ]}
        header={intl.formatMessage(messages.bankHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.bankRequired)),
        ]}
        onChange={(banks) => germanyContext.setState({ banks })}
        values={germanyContext.state.banks || []}
      />
    </Card>
  );
};
