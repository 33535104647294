import React, { FunctionComponent, useContext } from "react";
import { Slider } from "@lysaab/ui-2";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { SignupContext } from "../../../state/SignupContext";
import "./Slider.scss";

export const LiquidAssetsSlider: FunctionComponent = () => {
  const localizationContext = useContext(LocalizationContext);
  const signupContext = useContext(SignupContext);

  const steps = localizationContext.state.situation?.liquidAssets;

  if (!steps) {
    return null;
  }

  const max = steps.length - 1;
  const isMax =
    steps.indexOf(signupContext.state.financial.liquidAssets) === max;

  const label = (
    <div>
      <div className="situation-slider-text">
        <span>
          <FormattedMessage id="situation.slider.liquidassets.label" />
        </span>
        <span>
          {isMax && (
            <span style={{ fontWeight: "normal" }}>
              <FormattedMessage id="situation.slider.label.maxPrefix" />
            </span>
          )}
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={signupContext.state.financial.liquidAssets}
            currency={localizationContext.state.currency}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </span>
      </div>
      <p>
        <FormattedMessage id="situation.slider.liquidassets.description" />
      </p>
    </div>
  );

  return (
    <div className="situation-slider">
      <Slider
        min={0}
        max={steps.length - 1}
        step={1}
        value={steps.indexOf(signupContext.state.financial.liquidAssets)}
        onChange={(value) =>
          signupContext.setState({
            financial: {
              ...signupContext.state.financial,
              liquidAssets: steps[value],
            },
          })
        }
        label={label}
        data-test-id="liquid-assets-slider"
      />
    </div>
  );
};
