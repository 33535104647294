import React, { useCallback, useRef } from "react";
import {
  FormattedMessage,
  IntlShape,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from "react-intl";
import {
  Button,
  Form,
  LysaFormRef,
  RadioGroupSimpleCard,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import { SavingsHorizonLength } from "../../data/types/HorizonQuestions";
import { useAdvice } from "../../state/AdviceContext";
export const ROUTE = "/horizon-length";

interface Props {
  next: () => void;
}

export const HorizonLength: React.FC<Props> = ({ next }) => {
  const intl = useIntl();
  const advice = useAdvice();
  const formRef = useRef<LysaFormRef>();

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formRef.current?.isValid) {
        next();
      }
    },
    [next, formRef]
  );

  const alternatives = Object.values(SavingsHorizonLength).map((key) => ({
    text: getSavingsHorizonLengthAltText(intl, key),
    value: key,
  }));

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <RadioGroupSimpleCard
        legend={
          <>
            <Typography type="h3" component="span">
              <FormattedMessage id="horizon.length.header" />
            </Typography>
            <Typography>
              <FormattedMessage id="horizon.length.explanation" />
            </Typography>
          </>
        }
        alternatives={alternatives}
        value={advice.input.savingsHorizon}
        onChange={(value) => advice.setInput({ savingsHorizon: value })}
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "horizon.length.required" })
          ),
        ]}
        data-test-id="horizon-length"
      />

      <Typography>
        <FormattedMessage id="horizon.length.withdrawal" />
      </Typography>

      <Button
        block
        type="submit"
        data-test-id="horizon-length-next-button"
        label={<FormattedMessage id="horizon.length.button.next" />}
      />
    </Form>
  );
};

export function getSavingsHorizonLengthAltText(
  intl: IntlShape,
  key: SavingsHorizonLength
) {
  return intl.formatMessage(savingsHorizonLengthAltText[key]);
}

const savingsHorizonLengthAltText = defineMessages<
  SavingsHorizonLength,
  MessageDescriptor,
  Record<SavingsHorizonLength, MessageDescriptor>
>({
  [SavingsHorizonLength.VERY_LONG]: {
    id: "horizon.length.alt.verylong",
  },
  [SavingsHorizonLength.LONG]: {
    id: "horizon.length.alt.long",
  },
  [SavingsHorizonLength.KINDA_LONG]: {
    id: "horizon.length.alt.kindalong",
  },
  [SavingsHorizonLength.MIDDLE]: {
    id: "horizon.length.alt.middle",
  },
  [SavingsHorizonLength.SHORT]: {
    id: "horizon.length.alt.short",
  },
});
