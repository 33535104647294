import { useCallback, useContext, useEffect, useRef } from "react";
import {
  FlashContext,
  SNACKBAR_TYPES,
  Spinner,
  Story,
  WorldCountry,
} from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  matchPath,
} from "react-router-dom";
import { AboutYou } from "../pages/aboutYou/AboutYou";
import {
  SignupContextProvider,
  useSignupContext,
} from "../state/SignupContext";
import { Experience } from "../pages/experience/Experience";
import {
  LocalizationContext,
  Language,
  SituationSteps,
  Currency,
} from "../state/LocalizationContext";
import { SituationIntro } from "../pages/situation/SituationIntro";
import { SituationIncome } from "../pages/situation/SituationIncome";
import { SituationAssets } from "../pages/situation/SituationAssets";
import { RiskIntro } from "../pages/risk/RiskIntro";
import { RiskImportanceQuestion } from "../pages/risk/RiskImportanceQuestion";
import { RiskReactionQuestion } from "../pages/risk/RiskReactionQuestion";
import { RiskPropensityQuestion } from "../pages/risk/RiskPropensityQuestion";
import { HorizonIntro } from "../pages/horizon/HorizonIntro";
import {
  HorizonLength,
  ROUTE as HORIZON_LENGTH_ROUTE,
} from "../pages/horizon/HorizonLength";
import { HorizonProbability } from "../pages/horizon/HorizonProbability";
import { defineMessages, useIntl } from "react-intl";
import { Advice, ROUTE as ADVICE_ROUTE } from "../pages/advice/Advice";
import {
  EditAllocation,
  ROUTE as EDIT_ALLOCATION_ROUTE,
} from "../pages/EditAllocation/EditAllocation";
import {
  RiskWarning,
  ROUTE as RISK_WARNING_ROUTE,
} from "../pages/RiskWarning/RiskWarning";
import { Crs, ROUTE as CRS_ROUTE } from "./pages/crs/Crs";
import { useGermanyContext } from "./GermanyContext";
import { Kyc, ROUTE as KYC_ROUTE } from "./components/kyc/Kyc";
import {
  Citizenship,
  ROUTE as CITIZENSHIP_ROUTE,
} from "../pages/citizenship/citizenship";
import { generateTranslatedArray } from "../pages/situation/sliders/Interval";
import { useSafeNavigation } from "../hooks/useSafeNavigation";
import {
  OnfidoIntroPage,
  OnfidoPoaIntroPage,
  OnfidoStatusPage,
} from "@lysaab/lysa-onfido";
import {
  IsPepPage,
  RelationPage,
  PepContext,
  PepType,
  PepStatusPage,
  PepRolePage,
} from "@lysaab/lysa-pep";
import {
  VerifyEmail,
  ROUTE as EMAIL_ROUTE,
} from "../pages/verifyEmail/VerifyEmail";
import { Password, ROUTE as PASSWORD_ROUTE } from "../pages/Password/Password";
import { Totp, ROUTE as TOTP_ROUTE } from "../pages/Totp/Totp";
import { Done } from "../pages/done/Done";
import { ContactFooter } from "../components/ContactFooter";
import { AuthSelection } from "../pages/AuthSelection/AuthSelection";
import { NoDirectAccessRoute } from "../components/DirectAccessGuard";
import { Confirmation } from "../pages/confirmation/Confirmation";
import { Exit } from "../pages/exit/Exit";
import { MarketingEmails } from "./pages/marketingEmails/MarketingEmails";
import { Intro } from "./pages/intro/Intro";
import { onfidoMessages } from "../Onfido";
import { OnfidoProcessId } from "../pages/onfido/OnfidoProcessId";
import { OnfidoProcessPoa } from "../pages/onfido/OnfidoProcessPoa";
import { preventUnload } from "../components/intro/IntroEmailSection";
import { EventTracker, TrackerEvent } from "../utils/eventTracker/EventTracker";
import {
  Sustainability,
  ROUTE as SUSTAINABILITY_ROUTE,
} from "../pages/sustainability/Sustainability";
import { useSignupId } from "../hooks/useRouteSignupId";
import { SituationAnswersAccessGuard } from "../components/situationAnswersAccessGuard/SituationAnswersAccessGuard";
import { AboutYouAnswersAccessGuard } from "../components/aboutYouAnswersAccessGuard/AboutYouAnswersAccessGuard";
import { AdviceGuard } from "../components/adviceGuard/AdviceGuard";
import { CrsAnswersAccessGuard } from "../components/crsAnswersAccessGuard/CrsAnswersAccessGuard";
import { ReferralHandler } from "../components/ReferralHandler";
import { useNavigateOut } from "../hooks/useNavigateOut";
import { Plausible } from "../utils/Plausible";

const PREFIX = `/${LysaCountry.GERMANY.toLocaleLowerCase()}`;
const CURRENCY = Currency.EUR;

export const ROUTES = {
  INTRO: `${PREFIX}/`,
  MARKETING: `${PREFIX}/marketing`,
  EXPERIENCE: `${PREFIX}/experience`,
  SITUATION: `${PREFIX}/situation`,
  SITUATION_INCOME: `${PREFIX}/situation-income`,
  SITUATION_ASSETS: `${PREFIX}/situation-assets`,
  RISK: `${PREFIX}/risk`,
  RISK_IMPORTANCE: `${PREFIX}/risk-importance`,
  RISK_REACTION: `${PREFIX}/risk-reaction`,
  RISK_PROPENSITY: `${PREFIX}/risk-propensity`,
  HORIZON: `${PREFIX}/horizon`,
  HORIZON_LENGTH: `${PREFIX}${HORIZON_LENGTH_ROUTE}`,
  HORIZON_PROBABILITY: `${PREFIX}/horizon-probability`,
  SUSTAINABILITY: `${PREFIX}${SUSTAINABILITY_ROUTE}`,
  ADVICE: `${PREFIX}${ADVICE_ROUTE}`,
  EDIT_ALLOCATION: `${PREFIX}${EDIT_ALLOCATION_ROUTE}`,
  RISK_WARNING: `${PREFIX}${RISK_WARNING_ROUTE}`,
  ABOUT_YOU: `${PREFIX}/about-you`,
  CRS: `${PREFIX}${CRS_ROUTE}`,
  CITIZENSHIP: `${PREFIX}${CITIZENSHIP_ROUTE}`,
  KYC: `${PREFIX}${KYC_ROUTE}`,
  PEP_IS_PEP: `${PREFIX}/is-pep`,
  PEP_RELATION: `${PREFIX}/pep-relation`,
  PEP_STATUS: `${PREFIX}/pep-status`,
  PEP_ROLE: `${PREFIX}/pep-role`,
  PASSWORD: `${PREFIX}${PASSWORD_ROUTE}`,
  AUTH_SELECTION: `${PREFIX}/auth-selection`,
  TOTP_SETUP: `${PREFIX}${TOTP_ROUTE}`,
  EMAIL: `${PREFIX}${EMAIL_ROUTE}`,
  CONFIRM: `${PREFIX}/confirm`,
  ONFIDO_INTRO: `${PREFIX}/onfido-intro/:signupId`,
  ONFIDO_STATUS: `${PREFIX}/onfido-status/:signupId`,
  ONFIDO_PROCESS_ID: `${PREFIX}/onfido-process-id/:signupId`,
  ONFIDO_POA_INTRO: `${PREFIX}/onfido-poa-intro/:signupId`,
  ONFIDO_PROCESS_POA: `${PREFIX}/onfido-process-poa/:signupId`,
  POA: `${PREFIX}/poa/:signupId`,
  DONE: `${PREFIX}/done/:signupId`,
  EXIT: `${PREFIX}/exit`,
};

const incomeSteps = generateTranslatedArray([
  {
    step: 100,
    stop: 6_000,
  },
  {
    step: 500,
    stop: 11_000,
  },
]);

const liquidAssetsSteps = generateTranslatedArray([
  {
    step: 500,
    stop: 10_000,
  },
  {
    step: 1_000,
    stop: 100_000,
  },
  {
    step: 10_000,
    stop: 500_000,
  },
  {
    step: 50_000,
    stop: 1_000_000,
  },
]);

const assetSteps = generateTranslatedArray([
  {
    step: 1_000,
    stop: 100_000,
  },
  {
    step: 5_000,
    stop: 600_000,
  },
  {
    step: 10_000,
    stop: 1_000_000,
  },
]);

const situation: SituationSteps = {
  income: incomeSteps,
  expense: incomeSteps,
  debt: assetSteps,
  liquidAssets: liquidAssetsSteps,
  otherAssets: assetSteps,
};

const messages = defineMessages({
  header: { id: "germany.story.header" },
  ariaProgressLabel: { id: "germany.story.ariaProgressLabel" },
  countryName: { id: "germany.pep.countryName" },
});

const onfidoAllowedDocuments = ["passport", "national_identity_card"];

const Router = () => {
  const localizationContext = useContext(LocalizationContext);
  const pepContext = useContext(PepContext);
  const signupContext = useSignupContext();
  const germanyContext = useGermanyContext();
  const { pushFlash } = useContext(FlashContext);
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const loaded = useRef(false);
  const safeNavigation = useSafeNavigation();
  const signupId = useSignupId(ROUTES);
  const navigateOut = useNavigateOut();

  // Inside <OnfidoProcessId> we wrap this callback with a status check
  const onfidoProcessIdNext = useCallback(() => {
    safeNavigation(ROUTES.ONFIDO_POA_INTRO.replace(":signupId", signupId));
  }, [safeNavigation, signupId]);

  const onfidoDone = useCallback(() => {
    safeNavigation(ROUTES.DONE.replace(":signupId", signupId));
  }, [safeNavigation, signupId]);

  const onfidoPoaIntroPageNext = useCallback(() => {
    safeNavigation(ROUTES.ONFIDO_PROCESS_POA.replace(":signupId", signupId));
  }, [safeNavigation, signupId]);

  const onfidoGoToId = useCallback(() => {
    safeNavigation(ROUTES.ONFIDO_PROCESS_ID.replace(":signupId", signupId));
  }, [safeNavigation, signupId]);

  const onfidoGoToStatus = useCallback(() => {
    safeNavigation(ROUTES.ONFIDO_STATUS.replace(":signupId", signupId));
  }, [safeNavigation, signupId]);

  const onfidoRestart = useCallback(() => {
    window.location.href = ROUTES.INTRO;
  }, []);

  const onfidoFatalError = useCallback(() => {
    if (signupContext.state.onfidoError) {
      return;
    }

    signupContext.setState({ onfidoError: true });

    pushFlash({
      text: intl.formatMessage(onfidoMessages.onfidoFatalError),
      type: SNACKBAR_TYPES.ERROR,
    });

    window.removeEventListener("beforeunload", preventUnload);
    setTimeout(() => {
      window.location.href = ROUTES.INTRO;
    }, 4000);
  }, [signupContext, intl, pushFlash]);

  const onfidoUserError = useCallback(() => {
    if (signupContext.state.onfidoError) {
      return;
    }

    signupContext.setState({ onfidoError: true });

    pushFlash({
      text: intl.formatMessage(onfidoMessages.onfidoUserError),
      type: SNACKBAR_TYPES.ERROR,
    });

    window.removeEventListener("beforeunload", preventUnload);

    setTimeout(() => {
      // restart onfido process
      window.location.reload();
    }, 4000);
  }, [signupContext, pushFlash, intl]);

  const currentIndex = Object.values(ROUTES).findIndex((path) => {
    return (
      matchPath(location.pathname, {
        path,
        exact: path === ROUTES.INTRO,
      }) !== null
    );
  });

  const handleBack = () => {
    EventTracker.track({
      event: TrackerEvent.BACK_BUTTON,
    });
    history.goBack();
  };

  useEffect(() => {
    if (!loaded.current) {
      localizationContext.setState({
        currency: CURRENCY,
        language: Language.GERMAN,
        situation: situation,
        country: LysaCountry.GERMANY,
      });

      signupContext.setState({
        crsResidenceCountry: WorldCountry.GERMANY,
      });
    }

    loaded.current = true;
  }, [localizationContext, signupContext]);

  useEffect(() => {
    if (signupId && signupId !== EventTracker.signupId) {
      EventTracker.setSignupId(signupId);
    }
  }, [signupId]);

  if (!loaded.current) {
    return <Spinner />;
  }

  return (
    <Story
      header={intl.formatMessage(messages.header)}
      onExit={() => {
        if (currentIndex === Object.values(ROUTES).indexOf(ROUTES.INTRO)) {
          navigateOut.exitToPublicSite();
        } else if (
          currentIndex === Object.values(ROUTES).indexOf(ROUTES.EXIT)
        ) {
          Plausible.event({
            name: Plausible.events.EXIT_CLOSE,
          });
          navigateOut.exitToPublicSite();
        } else {
          history.push(ROUTES.EXIT, { from: location.pathname });
        }
      }}
      showBack={
        (currentIndex > 0 &&
          currentIndex < Object.values(ROUTES).indexOf(ROUTES.CONFIRM)) ||
        currentIndex === Object.values(ROUTES).indexOf(ROUTES.EXIT)
      }
      showClose={currentIndex !== Object.values(ROUTES).indexOf(ROUTES.DONE)}
      onBack={handleBack}
      transitionKey={currentIndex.toString()}
      progress={(100 / Object.keys(ROUTES).length) * (currentIndex + 1)}
      ariaLabelProgress={() =>
        intl.formatMessage(messages.ariaProgressLabel, {
          current: currentIndex + 1,
          total: Object.keys(ROUTES).length,
        })
      }
    >
      <Switch {...{ order: currentIndex }} location={location}>
        <Route path={ROUTES.INTRO} exact>
          <Intro next={() => safeNavigation(ROUTES.MARKETING)} />
          <ContactFooter />
        </Route>
        <NoDirectAccessRoute path={ROUTES.MARKETING}>
          <MarketingEmails next={() => safeNavigation(ROUTES.EXPERIENCE)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.EXPERIENCE}>
          <Experience next={() => safeNavigation(ROUTES.SITUATION)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.SITUATION}>
          <SituationIntro
            next={() => safeNavigation(ROUTES.SITUATION_INCOME)}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.SITUATION_INCOME}>
          <SituationIncome
            next={() => safeNavigation(ROUTES.SITUATION_ASSETS)}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.SITUATION_ASSETS}>
          <SituationAssets next={() => safeNavigation(ROUTES.RISK)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.RISK}>
          <SituationAnswersAccessGuard situationRoute={ROUTES.SITUATION}>
            <RiskIntro next={() => safeNavigation(ROUTES.RISK_IMPORTANCE)} />
            <ContactFooter />
          </SituationAnswersAccessGuard>
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.RISK_IMPORTANCE}>
          <RiskImportanceQuestion
            next={() => safeNavigation(ROUTES.RISK_REACTION)}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.RISK_REACTION}>
          <RiskReactionQuestion
            next={() => safeNavigation(ROUTES.RISK_PROPENSITY)}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.RISK_PROPENSITY}>
          <RiskPropensityQuestion next={() => safeNavigation(ROUTES.HORIZON)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.HORIZON}>
          <HorizonIntro next={() => safeNavigation(ROUTES.HORIZON_LENGTH)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.HORIZON_LENGTH}>
          <HorizonLength
            next={() => safeNavigation(ROUTES.HORIZON_PROBABILITY)}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.HORIZON_PROBABILITY}>
          <HorizonProbability
            next={() => safeNavigation(ROUTES.SUSTAINABILITY)}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute exact path={ROUTES.SUSTAINABILITY}>
          <Sustainability next={() => safeNavigation(ROUTES.ADVICE)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.ADVICE}>
          <Advice next={() => safeNavigation(ROUTES.ABOUT_YOU)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.EDIT_ALLOCATION}>
          <EditAllocation
            next={() => safeNavigation(ROUTES.ABOUT_YOU)}
            nextRiskWarning={() => safeNavigation(ROUTES.RISK_WARNING)}
            allowInvestmentTypeChange={false}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.RISK_WARNING}>
          <RiskWarning next={() => safeNavigation(ROUTES.ABOUT_YOU)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.ABOUT_YOU}>
          <AboutYou next={() => safeNavigation(ROUTES.CRS)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <Route path={ROUTES.CRS}>
          <AboutYouAnswersAccessGuard aboutYouRoute={ROUTES.ABOUT_YOU}>
            <Crs
              birthdayField
              next={() => safeNavigation(ROUTES.CITIZENSHIP)}
            />
            <ContactFooter />
          </AboutYouAnswersAccessGuard>
        </Route>
        <NoDirectAccessRoute path={ROUTES.CITIZENSHIP}>
          <CrsAnswersAccessGuard crsRoute={ROUTES.CRS}>
            <Citizenship next={() => safeNavigation(ROUTES.KYC)} />
            <ContactFooter />
          </CrsAnswersAccessGuard>
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.KYC}>
          <Kyc next={() => safeNavigation(ROUTES.PEP_IS_PEP)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_IS_PEP}>
          <IsPepPage
            next={() => {
              if (pepContext.state.type === PepType.NOT_PEP) {
                safeNavigation(ROUTES.PASSWORD);
              } else if (pepContext.state.type === PepType.ME) {
                safeNavigation(ROUTES.PEP_STATUS);
              } else {
                safeNavigation(ROUTES.PEP_RELATION);
              }
            }}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_RELATION}>
          <RelationPage next={() => safeNavigation(ROUTES.PEP_STATUS)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_STATUS}>
          <PepStatusPage
            countryCode={WorldCountry.GERMANY}
            countryName={intl.formatMessage(messages.countryName)}
            next={() => safeNavigation(ROUTES.PEP_ROLE)}
            language={Language.GERMAN}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_ROLE}>
          <PepRolePage
            next={() => safeNavigation(ROUTES.PASSWORD)}
            language={Language.GERMAN}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PASSWORD}>
          <Password next={() => safeNavigation(ROUTES.AUTH_SELECTION)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.AUTH_SELECTION}>
          <AuthSelection
            nextTotp={() => safeNavigation(ROUTES.TOTP_SETUP)}
            nextEmail={() => safeNavigation(ROUTES.EMAIL)}
          />
          <ContactFooter />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.TOTP_SETUP}>
          <Totp next={() => safeNavigation(ROUTES.EMAIL)} />
          <ContactFooter />
        </NoDirectAccessRoute>
        <Route path={ROUTES.EMAIL}>
          <VerifyEmail next={() => safeNavigation(ROUTES.CONFIRM)} />
          <ContactFooter />
        </Route>
        <NoDirectAccessRoute path={ROUTES.CONFIRM}>
          <AboutYouAnswersAccessGuard aboutYouRoute={ROUTES.ABOUT_YOU}>
            <AdviceGuard adviceRoute={ROUTES.ADVICE}>
              <SituationAnswersAccessGuard situationRoute={ROUTES.SITUATION}>
                <CrsAnswersAccessGuard crsRoute={ROUTES.CRS}>
                  <Confirmation
                    banks={germanyContext.state.banks}
                    deposits={germanyContext.state.deposits}
                    next={(signupIdParam) => {
                      safeNavigation(
                        ROUTES.ONFIDO_INTRO.replace(
                          ":signupId",
                          signupIdParam || signupId
                        )
                      );
                    }}
                  />
                  <ContactFooter />
                </CrsAnswersAccessGuard>
              </SituationAnswersAccessGuard>
            </AdviceGuard>
          </AboutYouAnswersAccessGuard>
        </NoDirectAccessRoute>
        <Route path={ROUTES.ONFIDO_INTRO}>
          <OnfidoIntroPage
            next={() =>
              safeNavigation(
                ROUTES.ONFIDO_PROCESS_ID.replace(":signupId", signupId)
              )
            }
            signupId={signupId}
            language={Language.GERMAN}
            country={LysaCountry.GERMANY}
          />
          <ContactFooter />
        </Route>
        <Route path={ROUTES.ONFIDO_STATUS}>
          <OnfidoStatusPage
            idProcess={() =>
              safeNavigation(
                ROUTES.ONFIDO_PROCESS_ID.replace(":signupId", signupId)
              )
            }
            poaProcess={() =>
              safeNavigation(
                ROUTES.ONFIDO_PROCESS_POA.replace(":signupId", signupId)
              )
            }
            restart={() => window.location.reload()}
          />
          <ContactFooter />
        </Route>
        <Route path={ROUTES.ONFIDO_PROCESS_ID}>
          <OnfidoProcessId
            signupId={signupId}
            next={onfidoProcessIdNext}
            done={onfidoDone}
            restart={onfidoRestart}
            onFatalError={onfidoFatalError}
            onUserError={onfidoUserError}
            allowedDocuments={onfidoAllowedDocuments}
            country={LysaCountry.GERMANY}
          />
          <ContactFooter />
        </Route>
        <Route path={ROUTES.ONFIDO_POA_INTRO}>
          <OnfidoPoaIntroPage
            signupId={signupId}
            next={onfidoPoaIntroPageNext}
            language={Language.GERMAN}
          />
        </Route>
        <Route path={ROUTES.ONFIDO_PROCESS_POA}>
          <OnfidoProcessPoa
            signupId={signupId}
            next={onfidoDone}
            restart={onfidoRestart}
            goToId={onfidoGoToId}
            goToStatus={onfidoGoToStatus}
            onFatalError={onfidoFatalError}
            onUserError={onfidoUserError}
            country={LysaCountry.GERMANY}
          />
          <ContactFooter />
        </Route>
        <Route path={ROUTES.DONE}>
          <Done signupId={signupId} />
          <ContactFooter />
        </Route>
        <Route path={ROUTES.EXIT}>
          <Exit />
          <ContactFooter />
        </Route>
      </Switch>
    </Story>
  );
};

export const GermanyRouter: React.FC = () => {
  return (
    <SignupContextProvider defaultInvestment={1_000}>
      <ReferralHandler />
      <Router />
    </SignupContextProvider>
  );
};
