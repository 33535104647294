import React, { useContext } from "react";
import { Card, RequiredValidator, CheckboxGroup } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { MoneyOrigin } from "./SharedKyc";
import { SignupContext } from "../../state/SignupContext";

export const messages = defineMessages({
  moneyOriginHeader: {
    id: "kyc.moneyOrigin.header",
  },
  moneyOriginRequired: {
    id: "kyc.moneyOrigin.required",
  },
  [MoneyOrigin.SAVINGS]: {
    id: "kyc.MoneyOrigin.alt.SAVINGS",
  },
  [MoneyOrigin.INHERITANCE]: {
    id: "kyc.MoneyOrigin.alt.INHERITANCE",
  },
  [MoneyOrigin.INCOME]: {
    id: "kyc.MoneyOrigin.alt.INCOME",
  },
  [MoneyOrigin.PROPERTY_SALE]: {
    id: "kyc.MoneyOrigin.alt.PROPERTY_SALE",
  },
  [MoneyOrigin.CORPORATION_SALE]: {
    id: "kyc.MoneyOrigin.alt.CORPORATION_SALE",
  },
  [MoneyOrigin.TRADE]: {
    id: "kyc.MoneyOrigin.alt.TRADE",
  },
  [MoneyOrigin.DIVIDEND]: {
    id: "kyc.MoneyOrigin.alt.DIVIDEND",
  },
});

export const MoneyOriginQuestion = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  const alternatives = [
    {
      text: intl.formatMessage(messages[MoneyOrigin.SAVINGS]),
      value: MoneyOrigin.SAVINGS,
    },
    {
      text: intl.formatMessage(messages[MoneyOrigin.INHERITANCE]),
      value: MoneyOrigin.INHERITANCE,
    },
    {
      text: intl.formatMessage(messages[MoneyOrigin.INCOME]),
      value: MoneyOrigin.INCOME,
    },
    {
      text: intl.formatMessage(messages[MoneyOrigin.PROPERTY_SALE]),
      value: MoneyOrigin.PROPERTY_SALE,
    },
    {
      text: intl.formatMessage(messages[MoneyOrigin.CORPORATION_SALE]),
      value: MoneyOrigin.CORPORATION_SALE,
    },
    {
      text: intl.formatMessage(messages[MoneyOrigin.TRADE]),
      value: MoneyOrigin.TRADE,
    },
    {
      text: intl.formatMessage(messages[MoneyOrigin.DIVIDEND]),
      value: MoneyOrigin.DIVIDEND,
    },
  ];

  const value = Array.isArray(signupContext.state.moneyOrigin)
    ? alternatives.filter((alt) =>
        signupContext.state.moneyOrigin?.includes(alt.value)
      )
    : [];

  return (
    <Card>
      <CheckboxGroup
        headerType="large"
        alternatives={alternatives}
        header={intl.formatMessage(messages.moneyOriginHeader)}
        validators={[
          new RequiredValidator(
            intl.formatMessage(messages.moneyOriginRequired)
          ),
        ]}
        onChange={(moneyOrigin) =>
          signupContext.setState({
            moneyOrigin: moneyOrigin.map((alt) => alt.value),
          })
        }
        values={value}
        data-test-id="kyc-money-origin"
      />
    </Card>
  );
};
