import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import React, { useContext } from "react";
import {
  Alternative,
  RadioGroupSimpleCard,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import { SignupContext } from "../../../state/SignupContext";

const messages = defineMessages({
  spamAlternativeYes: { id: "germany.marketing.yes" },
  spamAlternativeNo: { id: "germany.marketing.no" },
  spamHeader: { id: "germany.marketing.text" },
  spamRequired: { id: "germany.marketing.required" },
});

export const SpamQuestion = () => {
  const signupContext = useContext(SignupContext);
  const intl = useIntl();

  const spamAlternatives: Alternative<boolean>[] = [
    {
      text: intl.formatMessage(messages.spamAlternativeYes),
      value: true,
    },
    {
      text: intl.formatMessage(messages.spamAlternativeNo),
      value: false,
    },
  ];

  return (
    <>
      <RadioGroupSimpleCard
        legend={intl.formatMessage(messages.spamHeader)}
        alternatives={spamAlternatives}
        value={signupContext.state.spam}
        onChange={(value) => signupContext.setState({ spam: value })}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.spamRequired)),
        ]}
        data-test-id="send-reminders"
      />
      <Typography type="body-small">
        <FormattedMessage id="germany.marketing.withdraw" />
      </Typography>
    </>
  );
};
