import React, { useRef, useContext, useState } from "react";
import { LysaCountry } from "@lysaab/shared";
import {
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
  Typography,
} from "@lysaab/ui-2";
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from "react-intl";
import { LiquidAssetsSlider } from "./sliders/LiquidAssentsSlider";
import { OtherAssetsSlider } from "./sliders/OtherAssetsSlider";
import { DebtSlider } from "./sliders/DebtSlider";
import { SignupContext } from "../../state/SignupContext";
import SituationUtils, {
  SituationValidationResult,
} from "../../utils/SituationUtils";
import { ContactEmailAddresses, ContactPhoneNumbers } from "../../Contact";
import { LocalizationContext } from "../../state/LocalizationContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { DescriptionModal } from "../../components/descriptionModal/DescriptionModal";
import { Modal } from "../../components/modal/Modal";

import "./SituationAssets.scss";

interface Props {
  next: () => void;
}

export const SituationAssets: React.FC<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const signupContext = useContext(SignupContext);
  const [warningStatus, setWarningStatus] = useState<
    "FIRST_ATTEMPT" | "VERIFICATION" | "SECOND_ATTEMPT" | "REJECTION"
  >("FIRST_ATTEMPT");
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!Boolean(formRef.current?.isValid)) {
      return;
    }

    const {
      liquidAssets,
      monthlyPayments,
      otherAssets,
      debts,
      monthlyEarnings,
    } = signupContext.state.financial;

    const situationValidationResult = SituationUtils.validate(
      liquidAssets,
      monthlyPayments,
      otherAssets,
      debts,
      monthlyEarnings
    );

    EventTracker.track({
      event: TrackerEvent.SITUATION,
      message: {
        liquidAssetsSlider: liquidAssets,
        expenseSlider: monthlyPayments,
        otherAssetsSlider: otherAssets,
        debtSlider: debts,
        incomeSlider: monthlyEarnings,
        result: situationValidationResult,
      },
    });

    if (situationValidationResult === SituationValidationResult.VALID) {
      next();
    } else if (warningStatus === "FIRST_ATTEMPT") {
      setWarningStatus("VERIFICATION");
    } else {
      setWarningStatus("REJECTION");
    }
  };

  return (
    <>
      <Form
        className="situation-assets"
        lysaFormRef={formRef}
        onSubmit={onSubmit}
      >
        <Typography type="h2">
          <FormattedMessage id="situation.assets.header" />
        </Typography>
        <Typography type="body">
          <FormattedMessage id="situation.assets.intro" />
        </Typography>
        <section>
          <LiquidAssetsSlider />
          <OtherAssetsSlider />
          <DebtSlider />
        </section>
        {warningStatus === "REJECTION" && country && (
          <InsufficientMeansError
            liquidAssets={signupContext.state.financial.liquidAssets}
            monthlyPayments={signupContext.state.financial.monthlyPayments}
            otherAssets={signupContext.state.financial.otherAssets}
            debts={signupContext.state.financial.debts}
            monthlyEarnings={signupContext.state.financial.monthlyEarnings}
            country={country}
          />
        )}
        <Button
          block
          type="submit"
          data-test-id="situation-assets-next-button"
          label={<FormattedMessage id="situation.assets.button.next" />}
        />
      </Form>

      <Modal
        className="situation-assets-modal"
        showModal={warningStatus === "VERIFICATION"}
        hideHeader
        width={360}
      >
        <Typography className="situation-assets-modal__header" type="h3">
          <FormattedMessage id="situation.assets.verification.header" />
        </Typography>
        <Typography>
          <FormattedMessage id="situation.assets.verification.body" />
        </Typography>
        <Button
          type="button"
          block
          label={<FormattedMessage id="situation.assets.verification.button" />}
          onClick={() => {
            setWarningStatus("SECOND_ATTEMPT");
          }}
        />
      </Modal>
    </>
  );
};

type CountryMessageKey = "header" | "content";

const swedenMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "sweden.situation.assets.modal.header",
  },
  content: {
    id: "sweden.situation.assets.modal.content",
  },
});
const denmarkMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "denmark.situation.assets.modal.header",
  },
  content: {
    id: "denmark.situation.assets.modal.content",
  },
});
const germanyMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "germany.situation.assets.modal.header",
  },
  content: {
    id: "germany.situation.assets.modal.content",
  },
});
const finlandMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "finland.situation.assets.modal.header",
  },
  content: {
    id: "finland.situation.assets.modal.content",
  },
});
const spainMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "sweden.situation.assets.modal.header",
  },
  content: {
    id: "sweden.situation.assets.modal.content",
  },
});

const messages: Record<
  LysaCountry,
  Record<CountryMessageKey, MessageDescriptor>
> = {
  [LysaCountry.SWEDEN]: swedenMessages,
  [LysaCountry.DENMARK]: denmarkMessages,
  [LysaCountry.GERMANY]: germanyMessages,
  [LysaCountry.FINLAND]: finlandMessages,
  [LysaCountry.SPAIN]: spainMessages,
};

function InsufficientMeansError({
  liquidAssets,
  monthlyPayments,
  otherAssets,
  debts,
  monthlyEarnings,
  country,
}: {
  liquidAssets: number;
  monthlyPayments: number;
  otherAssets: number;
  debts: number;
  monthlyEarnings: number;
  country: LysaCountry;
}) {
  const intl = useIntl();

  if (!SituationUtils.hasSufficientBuffer(liquidAssets, monthlyPayments)) {
    return (
      <Snackbar type={SNACKBAR_TYPES.INFO} icon>
        <div className="error-wrapper">
          <Typography type="body">
            <FormattedMessage id="situation.assets.insufficient.buffer.text" />
          </Typography>
          <Typography type="body">
            <FormattedMessage
              id="situation.assets.insufficient.buffer.contact"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[country]
                  );
                  return (
                    <a href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                      {phoneNumber}
                    </a>
                  );
                },
                email: () => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[country]
                  );
                  return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </Typography>
          <Typography type="body">
            <FormattedMessage id="situation.assets.insufficient.buffer.recheck" />
          </Typography>
          <DescriptionModal
            header={intl.formatMessage(messages[country].header)}
            content={intl.formatMessage(messages[country].content, {
              phone: () => {
                const phoneNumber = intl.formatMessage(
                  ContactPhoneNumbers[country]
                );
                return (
                  <a href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                    {phoneNumber}
                  </a>
                );
              },
              email: () => {
                const emailAddress = intl.formatMessage(
                  ContactEmailAddresses[country]
                );
                return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
              },
            })}
          >
            <FormattedMessage id="situation.assets.insufficient.buffer.elevio" />
          </DescriptionModal>
        </div>
      </Snackbar>
    );
  } else if (
    !SituationUtils.hasSufficientMeans(
      liquidAssets,
      otherAssets,
      debts,
      monthlyEarnings
    )
  ) {
    return (
      <Snackbar type={SNACKBAR_TYPES.INFO} icon>
        <div className="error-wrapper">
          <Typography type="body">
            <FormattedMessage id="situation.assets.insufficient.means.text" />
          </Typography>
          <Typography type="body">
            <FormattedMessage
              id="situation.assets.insufficient.means.contact"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[country]
                  );
                  return (
                    <a href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                      {phoneNumber}
                    </a>
                  );
                },
                email: () => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[country]
                  );
                  return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </Typography>
          <Typography type="body">
            <FormattedMessage id="situation.assets.insufficient.means.recheck" />
          </Typography>
          <DescriptionModal
            header={intl.formatMessage(messages[country].header)}
            content={intl.formatMessage(messages[country].content, {
              phone: () => {
                const phoneNumber = intl.formatMessage(
                  ContactPhoneNumbers[country]
                );
                return (
                  <a href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                    {phoneNumber}
                  </a>
                );
              },
              email: () => {
                const emailAddress = intl.formatMessage(
                  ContactEmailAddresses[country]
                );
                return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
              },
            })}
          >
            <FormattedMessage id="situation.assets.insufficient.means.elevio" />
          </DescriptionModal>
        </div>
      </Snackbar>
    );
  } else if (
    SituationUtils.isRunningOutOfMeans(
      liquidAssets,
      monthlyEarnings,
      monthlyPayments
    )
  ) {
    return (
      <Snackbar type={SNACKBAR_TYPES.INFO} icon>
        <div className="error-wrapper">
          <Typography type="body">
            <FormattedMessage id="situation.assets.running.out.text" />
          </Typography>
          <Typography type="body">
            <FormattedMessage
              id="situation.assets.running.out.contact"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[country]
                  );
                  return (
                    <a href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                      {phoneNumber}
                    </a>
                  );
                },
                email: () => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[country]
                  );
                  return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </Typography>
          <Typography type="body">
            <FormattedMessage id="situation.assets.running.out.recheck" />
          </Typography>
          <DescriptionModal
            header={intl.formatMessage(messages[country].header)}
            content={intl.formatMessage(messages[country].content, {
              phone: () => {
                const phoneNumber = intl.formatMessage(
                  ContactPhoneNumbers[country]
                );
                return (
                  <a href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                    {phoneNumber}
                  </a>
                );
              },
              email: () => {
                const emailAddress = intl.formatMessage(
                  ContactEmailAddresses[country]
                );
                return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
              },
            })}
          >
            <FormattedMessage id="situation.assets.running.out.elevio" />
          </DescriptionModal>
        </div>
      </Snackbar>
    );
  } else {
    return null;
  }
}
