import React, { useCallback, useRef } from "react";
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from "react-intl";
import {
  Button,
  Form,
  LysaFormRef,
  RadioGroupSimpleCard,
  RadioGroupSimpleCardItem,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import { EligibilityRiskAnswer } from "../../data/types/Eligibility";
import { useAdvice } from "../../state/AdviceContext";
import { List } from "./components/list/List";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { Illustration } from "./components/illustration/Illustration";
import graphPropensity from "./components/illustration/graph_propensity.svg";
export enum RiskPropensity {
  BAD = 10,
  PRETTY_BAD = 8,
  GOOD = 6,
  PRETTY_GOOD = 4,
  TOO_RISKY = 2,
}

interface Props {
  next: () => void;
}

export const RiskPropensityQuestion: React.FC<Props> = ({ next }) => {
  const intl = useIntl();
  const advice = useAdvice();
  const formRef = useRef<LysaFormRef>();

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formRef.current?.isValid) {
        const {
          [EligibilityRiskAnswer.IMPORTANCE]: riskImportance,
          [EligibilityRiskAnswer.PROPENSITY]: riskPropensity,
          [EligibilityRiskAnswer.REACTION]: riskReaction,
        } = advice.input;

        if (riskImportance && riskPropensity && riskReaction) {
          EventTracker.track({
            event: TrackerEvent.RISK,
            message: { riskImportance, riskPropensity, riskReaction },
          });
        }

        next();
      }
    },
    [advice.input, next]
  );

  const alternatives = Object.values(RiskPropensity).reduce((acc, key) => {
    if (typeof key !== "string") {
      acc.push({
        value: key,
        text: {
          text: intl.formatMessage(propensityAltText[key], {
            strong: (parts: React.ReactNode[]) => <strong>{parts}</strong>,
          }),
          type: "body",
          variant: "primary",
        },
      });
    }
    return acc;
  }, [] as RadioGroupSimpleCardItem<RiskPropensity>[]);

  const value = advice.input[EligibilityRiskAnswer.PROPENSITY];

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <Typography component="span" type="body" variant="secondary">
        <b>
          <FormattedMessage id="risk.propensity.header" />
        </b>
      </Typography>

      <Typography component="p" type="h3">
        <FormattedMessage id="risk.propensity.question.part-one" />
      </Typography>

      <List
        items={[
          {
            text: {
              text: intl.formatMessage(
                { id: "risk.propensity.question.part-two" },
                {
                  strong: (parts: React.ReactNode[]) => (
                    <strong>{parts}</strong>
                  ),
                }
              ),
            },
            discColor: "var(--lysa-text-blue)",
          },
          {
            text: {
              text: intl.formatMessage(
                { id: "risk.propensity.question.part-three" },
                {
                  strong: (parts: React.ReactNode[]) => (
                    <strong>{parts}</strong>
                  ),
                }
              ),
            },
            discColor: "var(--lysa-text-summary-blue)",
          },
        ]}
      />

      <Illustration
        label={intl.formatMessage({ id: "risk.propensity.illustration" })}
        src={graphPropensity}
      />

      <RadioGroupSimpleCard
        legend={intl.formatMessage({
          id: "risk.propensity.question.part-four",
        })}
        alternatives={alternatives}
        value={value}
        onChange={(riskPropensity) =>
          advice.setInput({
            [EligibilityRiskAnswer.PROPENSITY]: riskPropensity,
          })
        }
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "risk.propensity.required" })
          ),
        ]}
        data-test-id="risk-propensity-radiogroup"
      />

      <Button
        type="submit"
        block
        label={<FormattedMessage id="risk.propensity.button.next" />}
        data-test-id="risk-propensity-next-button"
      />
    </Form>
  );
};

const propensityAltText = defineMessages<
  RiskPropensity,
  MessageDescriptor,
  Record<RiskPropensity, MessageDescriptor>
>({
  [RiskPropensity.BAD]: { id: "risk.propensity.alt.bad" },
  [RiskPropensity.PRETTY_BAD]: { id: "risk.propensity.alt.prettybad" },
  [RiskPropensity.GOOD]: { id: "risk.propensity.alt.good" },
  [RiskPropensity.PRETTY_GOOD]: { id: "risk.propensity.alt.prettygood" },
  [RiskPropensity.TOO_RISKY]: { id: "risk.propensity.alt.toorisky" },
});
