import React from "react";
import { Typography } from "@lysaab/ui-2";

import "./Illustration.scss";

interface Props {
  label: string;
  src: string;
}

export const Illustration: React.FC<Props> = ({ label, src }) => {
  return (
    <div className="Illustration" aria-hidden="true">
      <Typography type="label">{label}</Typography>
      <img className="Illustration__image" src={src} alt="" />
    </div>
  );
};
