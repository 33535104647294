import React from "react";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";

import "./StorySectionIntro.scss";

interface Props {
  icon: keyof typeof NewIcon;
  header: string;
  description: string;
  nextLabel: string;
  nextTestId: string;
  next: () => void;
}

export const StorySectionIntro: React.FC<Props> = ({
  icon,
  header,
  description,
  nextLabel,
  nextTestId,
  next,
}) => {
  const Icon = NewIcon[icon];

  return (
    <div className="StorySectionIntro">
      <Icon
        className="StorySectionIntro__icon"
        primaryColor="var(--lysa-icon-secondary)"
        size={72}
      />
      <Typography type="h1" component="h2">
        {header}
      </Typography>
      <Typography type="body" variant="secondary">
        {description}
      </Typography>
      <Button
        className="StorySectionIntro__button"
        type="button"
        block
        data-test-id={nextTestId}
        label={nextLabel}
        onClick={next}
      />
    </div>
  );
};
