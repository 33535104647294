import React, { useContext } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Card, CheckboxGroup, RequiredValidator } from "@lysaab/ui-2";
import { FinlandContext, Bank } from "../../FinlandContext";

const messages = defineMessages({
  bankHeader: { id: "finland.kyc.bank.header" },
  bankRequired: { id: "finland.kyc.bank.required" },
  [Bank.FI_OP]: { id: "finland.kyc.bank.alt.FI_OP" },
  [Bank.FI_NORDEA]: { id: "finland.kyc.bank.alt.FI_NORDEA" },
  [Bank.FI_DB]: { id: "finland.kyc.bank.alt.FI_DB" },
  [Bank.FI_HB]: { id: "finland.kyc.bank.alt.FI_HB" },
  [Bank.FI_SP]: { id: "finland.kyc.bank.alt.FI_SP" },
  [Bank.FI_OTHER]: { id: "finland.kyc.bank.alt.FI_OTHER" },
});

export const BankQuestion = () => {
  const intl = useIntl();
  const finlandContext = useContext(FinlandContext);

  return (
    <Card>
      <CheckboxGroup
        headerType="large"
        alternatives={[
          {
            text: intl.formatMessage(messages[Bank.FI_OP]),
            value: Bank.FI_OP,
          },
          {
            text: intl.formatMessage(messages[Bank.FI_NORDEA]),
            value: Bank.FI_NORDEA,
          },
          {
            text: intl.formatMessage(messages[Bank.FI_DB]),
            value: Bank.FI_DB,
          },
          {
            text: intl.formatMessage(messages[Bank.FI_HB]),
            value: Bank.FI_HB,
          },
          {
            text: intl.formatMessage(messages[Bank.FI_SP]),
            value: Bank.FI_SP,
          },
          {
            text: intl.formatMessage(messages[Bank.FI_OTHER]),
            value: Bank.FI_OTHER,
          },
        ]}
        header={intl.formatMessage(messages.bankHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.bankRequired)),
        ]}
        onChange={(banks) => finlandContext.setState({ banks })}
        values={finlandContext.state.banks || []}
      />
    </Card>
  );
};
