import React, { useContext } from "react";
import { RadioGroupSimpleCard, RequiredValidator } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { SwedenContext, Deposits } from "../../../SwedenContext";
import { LocalizationContext } from "../../../../state/LocalizationContext";

export const messages = defineMessages({
  depositsHeader: { id: "sweden.kyc.deposits.header" },
  depositsRequired: { id: "sweden.kyc.deposits.required" },
  [Deposits.SE_0_100K_SEK]: { id: "sweden.kyc.deposits.alt.SE_0_100K_SEK" },
  [Deposits.SE_100K_1M_SEK]: { id: "sweden.kyc.deposits.alt.SE_100K_1M_SEK" },
  [Deposits.SE_1M_5M_SEK]: { id: "sweden.kyc.deposits.alt.SE_1M_5M_SEK" },
  [Deposits.SE_5M_SEK]: { id: "sweden.kyc.deposits.alt.SE_5M_SEK" },
});

export const DepositsQuestion = () => {
  const intl = useIntl();
  const swedenContext = useContext(SwedenContext);
  const localizationContext = useContext(LocalizationContext);

  return (
    <RadioGroupSimpleCard
      alternatives={[
        {
          text: intl.formatMessage(messages[Deposits.SE_0_100K_SEK], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.SE_0_100K_SEK,
        },
        {
          text: intl.formatMessage(messages[Deposits.SE_100K_1M_SEK], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.SE_100K_1M_SEK,
        },
        {
          text: intl.formatMessage(messages[Deposits.SE_1M_5M_SEK], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.SE_1M_5M_SEK,
        },
        {
          text: intl.formatMessage(messages[Deposits.SE_5M_SEK], {
            currency: ([currencyStr]) =>
              intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
          }),
          value: Deposits.SE_5M_SEK,
        },
      ]}
      legend={intl.formatMessage(messages.depositsHeader)}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.depositsRequired)),
      ]}
      onChange={(deposits) => swedenContext.setState({ deposits })}
      value={swedenContext.state.deposits}
      data-test-id="kyc-deposits"
    />
  );
};
