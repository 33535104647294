import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Card,
  LysaFormRef,
  Form,
  countries,
  RequiredValidator,
  WorldCountry,
  Button,
  RadioGroupSimpleCard,
  Alternative,
} from "@lysaab/ui-2";
import { ReportingCountry, SignupContext } from "../../../state/SignupContext";
import {
  Language,
  LocalizationContext,
} from "../../../state/LocalizationContext";
import "./Crs.scss";
import { CrsReportingCountries } from "./CrsReportingCountries";
import { CrsCountrySelect } from "./CrsCountrySelect";
import { CrsAddress } from "./CrsAddress";
import { crsMessages } from "../../../pages/crs/CrsMessages";

interface Props {
  next: () => void;
}

const getCountryFromCountryCode = (countryCode: string, language: Language) => {
  return countries[language].find(({ value }) => {
    return value === countryCode;
  })?.label;
};

const messages = defineMessages({
  taxableInAnotherCountryLabel: {
    id: "sweden.crs.taxableInAnotherCountry.label",
  },
  taxableInAnotherCountryYes: { id: "sweden.crs.taxableInAnotherCountry.yes" },
  taxableInAnotherCountryNo: { id: "sweden.crs.taxableInAnotherCountry.no" },
  taxableInAnotherCountryRequired: {
    id: "sweden.crs.taxableInAnotherCountry.required",
  },
});

export const Crs: React.FC<Props> = ({ next }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const signupContext = useContext(SignupContext);
  const { crsReportingCountries, crsResidenceCountry } = signupContext.state;
  const localizationContext = useContext(LocalizationContext);
  const { language, country } = localizationContext.state;
  const [showCrsUserInputs, setShowCrsUserInputs] = useState<Boolean>(
    Boolean(crsReportingCountries && crsReportingCountries.length > 0)
  );

  const [taxResidenceAlternatives] = useState<Alternative<boolean>[]>([
    {
      text: intl.formatMessage(messages.taxableInAnotherCountryNo),
      value: false,
    },
    {
      text: intl.formatMessage(messages.taxableInAnotherCountryYes),
      value: true,
    },
  ]);

  useEffect(() => {
    if (crsReportingCountries === undefined) {
      signupContext.setState({
        crsReportingCountries: [],
        crsResidenceCountry: WorldCountry.SWEDEN,
      });
    }
  }, [signupContext, country, crsReportingCountries]);

  const resetCrsState = useCallback(
    (reportingCountries?: ReportingCountry[]) => {
      signupContext.setState({
        ...(reportingCountries && {
          crsReportingCountries: reportingCountries,
        }),
        crsResidenceCountry: WorldCountry.SWEDEN,
        crsCity: undefined,
        crsStreet: undefined,
        crsPostCode: undefined,
      });
    },
    [signupContext]
  );

  const showAddressInformation =
    crsResidenceCountry && crsResidenceCountry !== WorldCountry.SWEDEN;

  if (typeof country === "undefined") {
    return null;
  }

  return (
    <div className="crs">
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            next();
          }
        }}
      >
        <h1>{intl.formatMessage(crsMessages.header)}</h1>

        <RadioGroupSimpleCard
          legend={intl.formatMessage(messages.taxableInAnotherCountryLabel, {
            country: getCountryFromCountryCode(country, language),
          })}
          alternatives={taxResidenceAlternatives}
          value={showCrsUserInputs}
          onChange={(value) => {
            setShowCrsUserInputs(value);
            if (!value) {
              resetCrsState([]);
            } else {
              signupContext.setState({
                crsReportingCountries: [
                  {
                    tin: "",
                    country: undefined,
                  },
                ],
              });
            }
          }}
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.taxableInAnotherCountryRequired)
            ),
          ]}
        />
        {showCrsUserInputs && (
          <Card>
            <CrsReportingCountries />
          </Card>
        )}
        {showCrsUserInputs && (
          <Card>
            <CrsCountrySelect />
            {showAddressInformation && <CrsAddress />}
          </Card>
        )}
        <p>{intl.formatMessage(crsMessages.buttonNextConfirmText)}</p>
        <Button
          type="submit"
          block
          data-test-id="crs-next-button"
          label={intl.formatMessage(crsMessages.buttonNext)}
        />
      </Form>
    </div>
  );
};
