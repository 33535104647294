import {
  RadioGroupSimpleCard,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SFDRQuestionInfoModal } from "./modals/SFDRQuestionInfoModal";
import { getSFDRAltText, getSFDRQuestionText } from "./SFDRText";
import { SFDRImportance } from "../../../data/types/SustainabilityQuestions";

interface Props {
  sfdr?: SFDRImportance;
  setSfdr: (sfdr: SFDRImportance) => void;
}

export const SFDRQuestion: FunctionComponent<Props> = ({ sfdr, setSfdr }) => {
  const intl = useIntl();
  const alternatives = Object.values(SFDRImportance).map((key) => ({
    text: getSFDRAltText(intl, key),
    value: key,
  }));

  return (
    <RadioGroupSimpleCard
      data-test-id="sustainability-share-sfdr"
      alternatives={alternatives}
      legend={
        <>
          <Typography component="span">{getSFDRQuestionText(intl)}</Typography>
          <br />
          <span className="modal-button-spacing">
            <SFDRQuestionInfoModal />
          </span>
        </>
      }
      onChange={setSfdr}
      value={sfdr}
      validators={[
        new RequiredValidator(intl.formatMessage({ id: "esg.sfdr.required" })),
      ]}
    />
  );
};
