import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Card, CheckboxGroup, RequiredValidator } from "@lysaab/ui-2";
import { Purpose } from "./SharedKyc";
import { SignupContext } from "../../state/SignupContext";

export const messages = defineMessages({
  header: {
    id: "kyc.purpose.header",
  },
  required: {
    id: "kyc.purpose.required",
  },
  [Purpose.FINANCIAL_SECURITY]: {
    id: "kyc.purpose.alt.FINANCIAL_SECURITY",
  },
  [Purpose.PENSION]: {
    id: "kyc.purpose.alt.PENSION",
  },
  [Purpose.CONSUMPTION]: {
    id: "kyc.purpose.alt.CONSUMPTION",
  },
  [Purpose.GIFT]: {
    id: "kyc.purpose.alt.GIFT",
  },
  [Purpose.LONGTERM_INVESTMENTS]: {
    id: "kyc.purpose.alt.LONGTERM_INVESTMENTS",
  },
});

export const PurposeQuestion = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  const alternatives = [
    {
      text: intl.formatMessage(messages[Purpose.FINANCIAL_SECURITY]),
      value: Purpose.FINANCIAL_SECURITY,
    },
    {
      text: intl.formatMessage(messages[Purpose.PENSION]),
      value: Purpose.PENSION,
    },
    {
      text: intl.formatMessage(messages[Purpose.CONSUMPTION]),
      value: Purpose.CONSUMPTION,
    },
    {
      text: intl.formatMessage(messages[Purpose.GIFT]),
      value: Purpose.GIFT,
    },
    {
      text: intl.formatMessage(messages[Purpose.LONGTERM_INVESTMENTS]),
      value: Purpose.LONGTERM_INVESTMENTS,
    },
  ];

  const value = Array.isArray(signupContext.state.purpose)
    ? alternatives.filter((alt) =>
        signupContext.state.purpose?.includes(alt.value)
      )
    : [];

  return (
    <Card>
      <CheckboxGroup
        headerType="large"
        alternatives={alternatives}
        onChange={(purpose) =>
          signupContext.setState({ purpose: purpose.map((alt) => alt.value) })
        }
        values={value}
        header={intl.formatMessage(messages.header)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        data-test-id="kyc-purpose"
      />
    </Card>
  );
};
