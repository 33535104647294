import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  EmailInput,
  EmailValidator,
  Form,
  LysaFormRef,
  RadioGroupSimpleCard,
  RequiredValidator,
} from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Plausible } from "../../utils/Plausible";
import { preventUnload } from "../../components/intro/IntroEmailSection";
import { useHistory } from "react-router-dom";
import "./Exit.scss";
import { useNavigateOut } from "../../hooks/useNavigateOut";
import { useSignupContext } from "../../state/SignupContext";
import { enqueueReminderEmail } from "../../data/signup";
import { useLocalizationContext } from "../../state/LocalizationContext";

const messages = defineMessages({
  questionsHeader: { id: "exit.questions.header" },
  noReasonAlternative: { id: "exit.alt.no_reason" },
  laterAlternative: { id: "exit.alt.later" },
  complicatedAlternative: { id: "exit.alt.complicated" },
  longAlternative: { id: "exit.alt.long" },
  personalAlternative: { id: "exit.alt.personal" },
  otherAlternative: { id: "exit.alt.other" },
});

const emailMessages = defineMessages({
  email: { id: "exit.email.label" },
  emailRequired: { id: "exit.email.required" },
  emailValidation: { id: "exit.email.validator" },
  emailPlaceholder: { id: "exit.email.placeholder" },
  emailSuggestion: { id: "exit.email.suggestion" },
});

export enum ExitReason {
  NO_REASON = "no_reason",
  LATER = "later",
  COMPLICATED = "complicated",
  LONG = "long",
  PERSONAL = "personal",
  OTHER = "other",
  NO_ANSWER = "no_answer",
  CLOSE = "close",
}

export const Exit = () => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const signupContext = useSignupContext();
  const localizationContext = useLocalizationContext();
  const [reason, setReason] = useState<ExitReason>();
  const [email, setEmail] = useState(signupContext.state.email || "");
  const [remindByEmail, setRemindByEmail] = useState(false);
  const history = useHistory<{ from?: string }>();
  const navigateOut = useNavigateOut();
  const exitedFrom = history.location.state?.from;

  useEffect(() => {
    window.removeEventListener("beforeunload", preventUnload);
  }, []);

  const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (formRef.current?.isValid) {
      let emailPromise: Promise<unknown> = Promise.resolve();
      let analyticsPromise: Promise<void> = Promise.resolve();
      const analyticsProps: {
        remindByEmail: boolean;
        exitedFrom?: string;
      } = {
        remindByEmail: false,
      };

      if (exitedFrom) {
        analyticsProps.exitedFrom = exitedFrom;
      }

      if (
        email.length > 0 &&
        localizationContext.state.country &&
        remindByEmail
      ) {
        emailPromise = enqueueReminderEmail({
          email,
          language: localizationContext.state.language,
          country: localizationContext.state.country,
        });
        analyticsProps.remindByEmail = true;
      }

      switch (reason) {
        case ExitReason.NO_REASON:
          analyticsPromise = Plausible.event({
            name: Plausible.events.EXIT_NO_REASON,
            props: analyticsProps,
          });
          break;
        case ExitReason.LATER:
          analyticsPromise = Plausible.event({
            name: Plausible.events.EXIT_LATER,
            props: analyticsProps,
          });
          break;
        case ExitReason.COMPLICATED:
          analyticsPromise = Plausible.event({
            name: Plausible.events.EXIT_COMPLICATED,
            props: analyticsProps,
          });
          break;
        case ExitReason.LONG:
          analyticsPromise = Plausible.event({
            name: Plausible.events.EXIT_LONG,
            props: analyticsProps,
          });
          break;
        case ExitReason.PERSONAL:
          analyticsPromise = Plausible.event({
            name: Plausible.events.EXIT_PERSONAL,
            props: analyticsProps,
          });
          break;
        case ExitReason.OTHER:
          analyticsPromise = Plausible.event({
            name: Plausible.events.EXIT_OTHER,
            props: analyticsProps,
          });
          break;
        default:
          analyticsPromise = Plausible.event({
            name: Plausible.events.EXIT_NO_ANSWER,
            props: analyticsProps,
          });
          break;
      }

      // Because we are navigating away from the page immediately after
      // these events, it's possible the browser will cancel the requests
      // unless we wait for them.
      // If these were pure analytics events, we could use `navigator.sendBeacon`
      // to avoid waiting, but in this case this is more like a form post than
      // analytics.
      Promise.all([emailPromise, analyticsPromise]).finally(() => {
        navigateOut.exitToPublicSite();
      });
    }
  };

  const willSend = reason || remindByEmail;

  return (
    <div className="exit-page">
      <h1>
        <FormattedMessage id="exit.header" />
      </h1>
      <Form lysaFormRef={formRef} onSubmit={onSubmit}>
        <section>
          <RadioGroupSimpleCard
            alternatives={[
              {
                text: intl.formatMessage(messages.noReasonAlternative),
                value: ExitReason.NO_REASON,
              },
              {
                text: intl.formatMessage(messages.laterAlternative),
                value: ExitReason.LATER,
              },
              {
                text: intl.formatMessage(messages.complicatedAlternative),
                value: ExitReason.COMPLICATED,
              },
              {
                text: intl.formatMessage(messages.longAlternative),
                value: ExitReason.LONG,
              },
              {
                text: intl.formatMessage(messages.personalAlternative),
                value: ExitReason.PERSONAL,
              },
              {
                text: intl.formatMessage(messages.otherAlternative),
                value: ExitReason.OTHER,
              },
            ]}
            legend={intl.formatMessage(messages.questionsHeader)}
            onChange={setReason}
            value={reason}
            data-test-id="exit-questions"
          />
          <Card>
            <EmailInput
              label={intl.formatMessage(emailMessages.email)}
              value={email}
              onChange={(value) => setEmail(value)}
              placeholder={intl.formatMessage(emailMessages.emailPlaceholder)}
              forceValidation={remindByEmail}
              validators={
                remindByEmail
                  ? [
                      new RequiredValidator(
                        intl.formatMessage(emailMessages.emailRequired)
                      ),
                      new EmailValidator(
                        intl.formatMessage(emailMessages.emailValidation)
                      ),
                    ]
                  : []
              }
              suggestionMessage={intl.formatMessage(
                emailMessages.emailSuggestion,
                {
                  // This replacement is made inside <EmailInput>, so pass it along
                  suggestion: "{suggestion}",
                }
              )}
              data-test-id="email-input"
            />
            <Checkbox
              alternative={{
                text: intl.formatMessage({ id: "exit.email.remind" }),
                value: "REMIND_BY_EMAIL",
              }}
              checked={remindByEmail}
              onChange={() => {
                setRemindByEmail(
                  (currentRemindByEmail) => !currentRemindByEmail
                );
              }}
            />
          </Card>
        </section>

        <Button
          block
          type="submit"
          label={
            willSend ? (
              <FormattedMessage id="exit.button.send_and_exit" />
            ) : (
              <FormattedMessage id="exit.button.exit" />
            )
          }
        />

        <Button
          variant="secondary"
          block
          type="button"
          onClick={() => history.goBack()}
          label={<FormattedMessage id="exit.button.continue" />}
        />
      </Form>
    </div>
  );
};
