import {
  RadioGroupSimpleCard,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { PAIQuestionInfoModal } from "./modals/PAIQuestionInfoModal";
import { getPAIAltText, getPAIQuestionText } from "./PAIText";
import { PAIImportance } from "../../../data/types/SustainabilityQuestions";

interface Props {
  pai?: PAIImportance;
  setPai: (pai: PAIImportance) => void;
}

export const PAIQuestion: FunctionComponent<Props> = ({ pai, setPai }) => {
  const intl = useIntl();
  const alternatives = Object.values(PAIImportance).map((key) => ({
    text: getPAIAltText(intl, key),
    value: key,
  }));

  return (
    <RadioGroupSimpleCard
      data-test-id="sustainability-pai"
      alternatives={alternatives}
      legend={
        <>
          <Typography component="span">{getPAIQuestionText(intl)}</Typography>
          <br />
          <span className="modal-button-spacing">
            <PAIQuestionInfoModal />
          </span>
        </>
      }
      onChange={setPai}
      value={pai}
      validators={[
        new RequiredValidator(intl.formatMessage({ id: "esg.pai.required" })),
      ]}
    />
  );
};
