import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { RadioGroupSimpleCard, RequiredValidator } from "@lysaab/ui-2";
import { Employment } from "./SharedKyc";
import { SignupContext } from "../../state/SignupContext";

const messages = defineMessages({
  employmentHeader: { id: "kyc.employment.header" },
  employmentRequired: { id: "kyc.employment.required" },
  [Employment.EMPLOYEE]: { id: "kyc.employment.alt.EMPLOYEE" },
  [Employment.SELF_EMPLOYED]: { id: "kyc.employment.alt.SELF_EMPLOYED" },
  [Employment.RETIRED]: { id: "kyc.employment.alt.RETIRED" },
  [Employment.STUDENT]: { id: "kyc.employment.alt.STUDENT" },
  [Employment.OTHER]: { id: "kyc.employment.alt.OTHER" },
});

export const EmploymentQuestion = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  const alternatives = [
    {
      text: intl.formatMessage(messages[Employment.EMPLOYEE]),
      value: Employment.EMPLOYEE,
    },
    {
      text: intl.formatMessage(messages[Employment.SELF_EMPLOYED]),
      value: Employment.SELF_EMPLOYED,
    },
    {
      text: intl.formatMessage(messages[Employment.RETIRED]),
      value: Employment.RETIRED,
    },
    {
      text: intl.formatMessage(messages[Employment.STUDENT]),
      value: Employment.STUDENT,
    },
    {
      text: intl.formatMessage(messages[Employment.OTHER]),
      value: Employment.OTHER,
    },
  ];

  return (
    <RadioGroupSimpleCard
      alternatives={alternatives}
      legend={intl.formatMessage(messages.employmentHeader)}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.employmentRequired)),
      ]}
      onChange={(employment) => signupContext.setState({ employment })}
      value={signupContext.state.employment}
      data-test-id="kyc-employment"
    />
  );
};
