import React, { FunctionComponent } from "react";
import {
  RadioGroupSimpleCard,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { TaxonomyQuestionInfoModal } from "./modals/TaxonomyQuestionInfoModal";
import { getTaxonomyAltText, getTaxonomyQuestionText } from "./TaxonomyText";
import { TaxonomyImportance } from "../../../data/types/SustainabilityQuestions";

interface Props {
  taxonomy?: TaxonomyImportance;
  setTaxonomy: (taxonomy: TaxonomyImportance) => void;
}

export const TaxonomyQuestion: FunctionComponent<Props> = ({
  taxonomy,
  setTaxonomy,
}) => {
  const intl = useIntl();
  const alternatives = Object.values(TaxonomyImportance).map((key) => ({
    text: getTaxonomyAltText(intl, key),
    value: key,
  }));

  return (
    <RadioGroupSimpleCard
      data-test-id="sustainability-share-taxonomy"
      alternatives={alternatives}
      legend={
        <>
          <Typography component="span">
            {getTaxonomyQuestionText(intl)}
          </Typography>
          <br />
          <span className="modal-button-spacing">
            <TaxonomyQuestionInfoModal />
          </span>
        </>
      }
      onChange={setTaxonomy}
      value={taxonomy}
      validators={[
        new RequiredValidator(
          intl.formatMessage({ id: "esg.taxonomy.required" })
        ),
      ]}
    />
  );
};
