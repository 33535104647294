import React, { useCallback, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Form, LysaFormRef, Typography } from "@lysaab/ui-2";
import { IncomeSlider } from "./sliders/IncomeSlider";
import { ExpenseSlider } from "./sliders/ExpenseSlider";

interface Props {
  next: () => void;
}

export const SituationIncome: React.FC<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formRef.current?.isValid) {
        next();
      }
    },
    [next]
  );

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <Typography type="h2">
        <FormattedMessage id="situation.income.header" />
      </Typography>
      <Typography>
        <FormattedMessage id="situation.income.description" />
      </Typography>
      <section>
        <IncomeSlider />
        <ExpenseSlider />
      </section>
      <Button
        type="submit"
        block
        label={<FormattedMessage id="situation.income.button.next" />}
        data-test-id="situation-income-next-button"
      />
    </Form>
  );
};
